.homePageContainer5 {
  padding: 4rem 15rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}

@media (1281px <= width <= 1440px) {
  .homePageContainer5 {
    padding: 4rem 6rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
  }
}

@media (width<=1280px) {
  .homePageContainer5 {
    padding: 4rem 6rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
  }
}

.homePageContainer1 {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.homePageImg1 {
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .homePageImg1 {
    width: 100%;
    /* height: 42rem; */
  }
  .slick-dots {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 550px;
    /* left: 0; */
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@media only screen and (min-width: 1281px) {
  .homePageImg1 {
    width: 100%;
    /* height: 44rem; */
  }

  .slick-dots {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 620px;
    /* left: 0; */
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.homePageContainer1Content {
  position: absolute;
  top: 0px;
  height: 100%;
  padding: 0rem 0rem 7rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 0;
  width: 100%;
}

.homePageContainer1Content-Head {
  color: #ffffff;
  font-weight: 700;
}

.homePageContainer1Content-text {
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.2px;
  padding: 16px 0px;
}

.homePageContainer1Content-btn {
  background-color: #f26828;
  color: #ffffff;
  width: 9rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  opacity: 1;
  cursor: pointer;
}

.homePageContainer1Content-btn:hover {
  opacity: 0.8;
}

.homePageContainer2 {
  padding: 2.5rem 5rem 0rem 5rem;
  box-sizing: border-box;
  position: relative;
  /* background-color: grey; */
  width: 90%;
  display: block;
  margin: auto;
}

@media (1400px <= width <= 1500px) {
  .homePageContainer2 {
    padding: 2.5rem 3rem 0rem 3rem;
  }
}

@media (1200px <= width <= 1300px) {
  .homePageContainer2 {
    padding: 2.5rem 1.5rem 0rem 1.5rem;
  }
}

.homePageContainer2-arrow1 {
  position: absolute;
  left: 0;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  rotate: 180deg;
  background-color: #f26828;
  color: #fff;
  top: calc(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homePageContainer2-arrow2 {
  position: absolute;
  right: 0;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: #f26828;
  color: #fff;
  top: calc(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homePageImgDiv1A {
  width: 100%;
  box-shadow: 0px 0px 25px 2px #00000012;
  box-sizing: border-box;
  padding: 1.5rem 2rem;
  display: flex;
  background-color: #fafafa;
  height: 34rem;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: space-between;
}

.homePageImgDiv1U {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  height: 36rem;
  border-radius: 1rem;
}

@media (1400px <= width <=1500px) {
  .homePageImgDiv1A {
    padding: 1rem;
    height: 29rem;
  }

  .homePageImgDiv1U {
    padding: 1.5rem;
    height: 29rem;
  }
}

@media (1200px <= width <= 1300px) {
  .homePageImgDiv1A {
    padding: 1rem;
    height: 25rem;
  }

  .homePageImgDiv1U {
    padding: 1rem;
    height: 25rem;
  }
}

.slick-slide {
  transform: scale(0.8);
  transition: all 0.4s ease-in-out;
  padding: 32px 12px 32px 32px;
}

.slick-slide img {
  max-width: 100%;
  transition: all 0.4s ease-in-out;
  width: 100%;
}

.slick-center {
  transform: scale(1.1);
}

.homePageImgText1 {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 4rem 1rem 0rem;
  color: #000;
}

.homePageImgText1A {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 3rem 1rem 0rem;
}

@media (1400px <= width <= 1500px) {
  .homePageImgText1 {
    font-size: 18px;
    padding: 1rem 3rem 1rem 0rem;
  }

  .homePageImgText1A {
    font-size: 18px;
    padding: 1rem 2rem 1rem 0rem;
  }
}

@media (1200px <= width <= 1300px) {
  .homePageImgText1 {
    font-size: 16px;
    padding: 0rem 2rem 0rem 0rem;
  }

  .homePageImgText1A {
    font-size: 16px;
    padding: 1rem 2rem 1rem 0rem;
  }
}

.homePageImgBtn1 {
  background-color: #f26828;
  color: #ffffff;
  width: fit-content;
  padding: 0rem 2rem;
  height: 42px;
  text-align: center;
  border-radius: 0.5rem;
  margin: 0;
}

.homePageImgBtn1Ac {
  background-color: #f26828;
  color: #ffffff;
  width: fit-content;
  padding: 0rem 2rem;
  height: 45px;
  text-align: center;
  border-radius: 0.5rem;
  margin: 0;
}

.homePageContainer4 {
  padding: 2rem 12rem;
  box-sizing: border-box;
  position: relative;
}

.homePageContainer4-img {
  width: 100%;
}

.homePageContainer4-arrow1 {
  position: absolute;
  left: 3rem;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  rotate: 180deg;
  background-color: #f26828;
  color: #fff;
  top: calc(45%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homePageContainer4-arrow2 {
  position: absolute;
  right: 3rem;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  background-color: #f26828;
  color: #fff;
  top: calc(45%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homePageContainer4-boxU {
  background-color: #ffffff;
  box-shadow: 0px 0px 25px 2px #00000012;
  box-sizing: border-box;
  padding: 2rem;
  border: 1px solid #215071;
  border-radius: 0.5rem;
  height: 11rem;
  width: 25rem !important;
  display: block;
  margin: 2rem auto 0rem auto;
}

@media (1400px <= width <= 1500px) {
  .homePageContainer4 {
    padding: 2rem 5rem;
  }

  .homePageContainer4-boxU {
    padding: 1rem;
    height: 10rem;
    width: 21rem !important;
  }
}

@media (1200px <= width <= 1300px) {
  .homePageContainer4 {
    padding: 2rem 3rem;
  }

  .homePageContainer4-boxU {
    padding: 1rem;
    height: 9rem;
    width: 18rem !important;
  }
}

.homePageContainer4-mainBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.homePageContainer4-boxA {
  background-color: #ffffff;
  box-shadow: 0px 0px 25px 2px #00000012;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  position: absolute;
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (1280px <= width <= 1500px) {
  .homePageContainer4-boxA {
    padding: 0.5rem 1rem;
  }
}

.homePageContainer4-alignHead {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.homePageContainer4-dot {
  height: 4px;
  width: 4px;
  background-color: #000000;
  border-radius: 100%;
}
.homePageContainer4-headMainCont {
  position: relative;
}
.homePageContainer4-headMain {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  padding: 3rem 0rem 1rem 0rem;
}

.homePageContainer4-headMain-dadi {
  position: absolute;
  top: calc(80%);
  left: calc(56%);
}

.homePageContainer4-headMain-dadi1 {
  position: absolute;
  top: calc(80%);
  left: calc(55%);
}

.homePageContainer4-headMain-dadi2 {
  position: absolute;
  top: calc(90%);
  left: calc(55%);
}

.homePageContainer4-headMain-dadi3 {
  position: absolute;
  top: calc(90%);
  left: calc(65%);
}

.homePageContainer4-headMain-dadi4 {
  position: absolute;
  top: calc(70%);
  left: calc(63%);
}

.homePageContainer4-headMain-dadi5 {
  position: absolute;
  top: calc(70%);
  left: calc(61%);
}

.homePageContainer4-headMain-dadi6 {
  position: absolute;
  top: calc(90%);
  left: calc(72%);
}

.homePageContainer4-head {
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.homePageContainer4-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.contactUsPage-formInput1Divaaa {
  border: 1px double #215071;
  box-sizing: border-box;
  border-radius: 0.5rem;
  outline: none;
  height: 1rem;
  display: flex;
  justify-content: space-between;
}

@media (1400px <= width <= 1500px) {
  .homePageContainer4-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
}

@media screen and (max-width: 1280px) {
  .homePageContainer4-head {
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }

  .homePageContainer4-text {
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
}

@media (1199px>= width >= 1000px) {
  .homePageContainer4-head {
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
  .homePageContainer4-alignHead {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
  }
  .homePageContainer4-text {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
}

.homePageContainer5-Box {
  width: 70%;
  /* background-color: #fafafa; */
  /* box-shadow: 0px 2px 15px 0px #0000001a; */
  padding: 1.5rem;
  transition: 0.5s;
  border-radius: 0.5rem;
  height: 80px;
  /* transition: 0.5s ease-out; */
  cursor: pointer;
  margin-bottom: 16px;
}

.homePageContainer5-BoxBorder {
  width: 70%;
  /* background-color: #fafafa; */
  padding: 1.5rem;
  transition: 0.5s;
  border: 1px solid #215071;
  border-radius: 0.5rem;
  /* box-shadow: 0px 5.56px 14.82px 0px #7aaccf3d; */
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.homePageContainer5-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homePageContainer5-head {
  font-size: 22px;
  color: #000000;
  font-weight: 500;
  padding: 0.4rem 0rem;
}

.homePageContainer5-text {
  color: #8e8e8e;
  font-weight: 400;
  font-size: 1.125rem;
  width: 95%;
}

@media (1400px <= width <=1500px) {
  .homePageContainer5-head {
    font-size: 20px;
  }

  .homePageContainer5-text {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (1200px <= width <=1300px) {
  .homePageContainer5-head {
    font-size: 18px;
  }

  .homePageContainer5-text {
    font-size: 14px;
    line-height: 20px;
  }
}

.homePageContainer-downloads {
  width: 100%;
  position: relative;
  padding: 10rem 0rem;
}

.homePageContainer-downloadImg1 {
  width: 100%;
}

.homePageContainer-downloadImg2 {
  position: absolute;
  width: 42%;
  top: calc(22%);
  right: 5rem;
}

@media (1400px <= width <= 1500px) {
  .homePageContainer-downloadImg2 {
    top: calc(27%);
    width: calc(40%);
  }
}

@media (1200px <= width <= 1300px) {
  .homePageContainer-downloadImg2 {
    top: calc(28%);
    width: calc(41%);
  }
}

.homePageContainer-downloads1 {
  position: absolute;
  left: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: calc(33%);
}

.homePageContainer-downloadhead {
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.homePageContainer-downloadAlign {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 3.5rem;
}

.homePageContainer-downloadBtn {
  background-color: #f26828;
  height: 3.5rem;
  width: 10rem;
  border-radius: 0.5rem;
  color: #fff;
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.homePageContainer-downloadIcons {
  display: flex;
  padding: 1rem 0rem;
  gap: 1rem;
}

.homePageContainer-downloadIcons1 {
  width: calc(25%);
}

.lpImgContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 2rem;
}

.lpImgCo {
  width: 50% !important;
  position: relative;
  display: block;
  margin: auto;
}

.lpImgCoText {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.lpImgCoText1 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #000000;
  padding: 1rem 0rem;
}

.lpImgCo1 {
  width: 100% !important;
}

.lpImg {
  display: block !important;
  width: 100% !important;
  margin: auto !important;
  border-radius: 16px !important;
  /* height: 35rem !important; */
}

.homePageContainer4-arrow3 {
  position: absolute;
  left: 3rem;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  rotate: 180deg;
  background-color: #f26828;
  color: #fff;
  top: calc(45%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homePageContainer4-arrow4 {
  position: absolute;
  right: 3rem;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: #f26828;
  color: #fff;
  top: calc(45%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 430px) {
  .homePageImgBtn1Ac {
    background-color: #f26828;
    color: #ffffff;
    width: fit-content;
    padding: 0.5rem 2rem;
    height: 2rem;
    text-align: center;
    border-radius: 0.5rem;
    margin: 0;
    font-size: 10px;
  }

  .slick-dots {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 220px;
    /* left: 0; */
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  /* .test {
  background-color: grey;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.test1 {
  width: 45%;
  height: 100%;
}

.test2 {
  width: 10%;
  background-color: skyblue;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testMain {
  width: calc(20%);
  height: 100px;
  border-radius: 10px; 
  transition: width 0.5s, height 0.5s, border-radius 0.5s; 
}

.testMain.circular {
  width: 100px; 
  height: 100px;
  border-radius: 50%; 
}

.test3 {
  width: 45%;
  height: 100%;
} */

  .homePageContainer1 {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100%;
  }

  .homePageImg1 {
    width: 100%;
  }

  .homePageContainer1Content {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 0;
    height: 100%;
  }

  .homePageContainer1Content-Head {
    color: #ffffff;
    font-weight: 700;
  }

  .homePageContainer1Content-text {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.2px;
    padding: 16px 0px;
  }

  .homePageContainer1Content-btn {
    background-color: #f26828;
    color: #ffffff;
    width: 9rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    opacity: 1;
    cursor: pointer;
  }

  .homePageContainer1Content-btn:hover {
    opacity: 0.8;
  }

  .homePageContainer2 {
    padding: 0rem 0rem 1rem 0rem;
    box-sizing: border-box;
    position: relative;
  }

  .homePageContainer2-arrow1 {
    position: absolute;
    left: 0;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: #f26828;
    color: #fff;
    top: calc(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .homePageContainer2-arrow2 {
    position: absolute;
    right: 0;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: #f26828;
    color: #fff;
    top: calc(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .homePageImgDiv1A {
    width: 100%;
    box-shadow: 0px 0px 25px 2px #00000012;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    background-color: #fafafa;
    height: 20rem;
    border-radius: 1rem;
    flex-direction: column;
  }

  .homePageImgDiv1U {
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    display: none;
    gap: 0.5rem;
    background-color: transparent;
    height: 20rem;
    border-radius: 1rem;
    flex-direction: column;
  }

  .slick-slide {
    padding: 0px;
  }

  .slick-center {
    transform: scale(1.1);
  }

  .homePageImgText1 {
    font-size: 0.8rem;
    padding: 1rem 0rem 1rem 0rem;
  }

  .homePageImgBtn1 {
    background-color: #f26828;
    color: #ffffff;
    width: fit-content;
    padding: 0rem 1.3rem;
    text-align: center;
    height: 2rem;
    border-radius: 0.5rem;
    margin: 0;
    font-size: 12px;
  }

  .homePageContainer3 {
    padding: 0rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .homePageContainer3-div1 {
    width: calc(100%);
    background-color: #f5fbff;
    border: 1px double #215071;
    padding: 2rem;
  }

  .homePageContainer3-formMain {
    padding: 0rem 0rem;
    width: 100%;
  }

  .homePageContainer3-form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0.5rem 0rem;
    width: 100%;
    gap: 0.5rem;
  }

  .homePageContainer3-form1 {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    gap: 0.5rem;
    position: relative;
  }

  .homePageContainer3-formLabel {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .homePageContainer3-formInput {
    border: 1px double #215071;
    box-sizing: border-box;
    padding: 0.5rem 0.4rem;
    border-radius: 0.5rem;
    outline: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .homePageContainer3-formInput1Div {
    border: 1px double #215071;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
  }

  .homePageContainer3-formInput1Div-code {
    font-size: 12px;
  }

  .homePageContainer3-formInput1Div1 {
    border-right: 1px solid #215071;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 100%;
    width: calc(25%);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.2rem;
  }

  .homePageContainer3-formInput1 {
    box-sizing: border-box;
    padding: 0.3rem 0.4rem;
    width: calc(75%);
    border-radius: 0.5rem;
    outline: none;

    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.09em;
    text-align: left;
    color: #000000;
  }

  .homePageContainer3-formInput1Div2 {
    border: 1px double #215071;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.5rem;
    background-color: #fff;
    align-items: center;
    opacity: 1;
    cursor: pointer;
  }

  .homePageContainer3-calendar {
    position: absolute;
    top: 4rem;
    z-index: 1;
  }

  .homePageContainer3-Time {
    width: 90%;
    border-radius: 1rem 0rem;
    height: 12rem;
    background-color: #ffffff;
    position: absolute;
    top: 4rem;
    border: 0.4px solid #215071;
    box-sizing: border-box;
    padding: 1rem 0rem;
  }

  .homePageContainer3-TimeHead {
    text-align: center;
    text-decoration: underline #000000;
    text-underline-offset: 0.4em;
    text-decoration-thickness: 0px;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .homePageContainer3-TimeHead-align {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 0.8rem;
  }

  .homePageContainer3-Btn {
    width: 100%;
    background-color: #215071;
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    margin: 1rem 0rem;
    padding: 0.5rem 0rem;
    border-radius: 1rem;
  }

  .homePageContainer3-formInput1Div2:hover {
    opacity: 0.8;
  }

  .homePageContainer3-div2 {
    width: calc(100%);
    background-color: #215071;
    position: relative;
    display: none;
  }

  .homePageContainer3-div2-div {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 2rem;
  }

  .homePageContainer3-div2-divdiv {
    padding: 0rem 0rem;
  }

  .homePageContainer3-head1 {
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    width: 50%;
  }

  .homePageContainer3-head11 {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .homePageContainer3-text1 {
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #838383;
    width: 70%;
  }

  .homePageContainer3-text11 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justify;
    color: #ffffff;
  }

  .homePageContainer3-rightDiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0rem;
  }

  .homePageContainer3-rightDiv1 {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .homePageContainer3-rightDivText {
    margin: 0;
    color: #ffffff;

    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  .homePageContainer3-iconDiv {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0rem;
  }

  .homePageContainer3-icons {
    padding: 4px;
    background-color: #fff;
    border-radius: 100%;
    font-size: 0.5rem;
    cursor: pointer;
    opacity: 1;
  }

  .homePageContainer3-icons:hover {
    opacity: 0.8;
  }

  .homePageContainer4 {
    padding: 0rem 0rem;
    box-sizing: border-box;
    position: relative;
  }

  .homePageContainer4-img {
    width: 78% !important;
  }

  .homePageContainer4-arrow1 {
    position: absolute;
    left: 1rem;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    rotate: 180deg;
    background-color: #f26828;
    color: #fff;
    top: calc(45%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .homePageContainer4-arrow2 {
    position: absolute;
    right: 1rem;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: #f26828;
    color: #fff;
    top: calc(45%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .homePageContainer4-boxU {
    background-color: #ffffff;
    box-shadow: 0px 0px 25px 2px #00000012;
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid #215071;
    border-radius: 0.5rem;
  }

  .homePageContainer4-mainBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .homePageContainer4-boxA {
    background-color: #ffffff;
    box-shadow: 0px 0px 25px 2px #00000012;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.5rem;
    position: absolute;
    width: 60%;
    height: 70%;
  }

  .homePageContainer4-alignHead {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
  }

  .homePageContainer4-dot {
    height: 4px;
    width: 4px;
    background-color: #000000;
    border-radius: 100%;
  }

  .homePageContainer4-headMain {
    font-size: 18px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0rem 0rem 0rem 0rem;
  }

  .homePageContainer4-head {
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }

  .homePageContainer4-text {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 4px 0px;
  }

  .homePageContainer5 {
    padding: 2rem 0rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
  }

  .homePageContainer5-Box {
    width: 90%;
    background-color: #fafafa;
    box-shadow: 0px 1.67px 5.35px 0px #080f340f;
    padding: 1.5rem;
    transition: 0.5s;
    height: 90px;
  }

  .homePageContainer5-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .homePageContainer5-head {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    padding: 0.2rem 1rem 0.2rem 0rem;
  }

  .homePageContainer5-text {
    color: #8e8e8e;
    font-weight: 400;
    font-size: 12px;
    width: 90%;
  }

  .homePageContainer-downloads {
    width: 100%;
    position: relative;
    padding: 4rem 0rem;
  }

  .homePageContainer-downloadImg1 {
    width: 100%;
  }

  .homePageContainer-downloadImg2 {
    position: absolute;
    width: calc(60%);
    top: calc(5%);
    right: 0;
  }

  .homePageContainer-downloads1 {
    position: absolute;
    top: calc(35%);
    left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }

  .homePageContainer-downloadhead {
    /* font-family: Poppins; */
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .homePageContainer-downloadAlign {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: 2.5rem;
  }

  .homePageContainer-downloadBtn {
    background-color: #f26828;
    height: 1.4rem;
    width: 4rem;
    border-radius: 0.2rem;
    color: #fff;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
  }

  .homePageContainer-downloadIcons {
    display: flex;
    padding: 0.4rem 0rem 0.4rem 0rem;
    gap: 1rem;
  }

  .homePageContainer-downloadIcons1 {
    width: calc(15%);
  }

  .lpImgContainer {
    width: 100%;
  }

  .lpImgCo {
    width: 100% !important;
  }

  .lpImgCoText {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }

  .lpImgCo1 {
    width: 100% !important;
  }

  .lpImg {
    display: block !important;
    width: 100% !important;
    margin: auto !important;
    /* height: 35rem !important; */
  }

  .homePageContainer4-arrow3 {
    position: absolute;
    left: 1rem;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    rotate: 180deg;
    background-color: #f26828;
    color: #fff;
    top: calc(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .homePageContainer4-arrow4 {
    position: absolute;
    right: 1rem;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: #f26828;
    color: #fff;
    top: calc(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .homePageContainer5-BoxBorder {
    width: 90%;
    background-color: #fafafa;
    padding: 1.5rem 1.5rem 1rem;
    transition: 0.5s;
    border: 1px solid #215071;
    border-radius: 0.5rem;
    box-shadow: 0px 5.56px 14.82px 0px #7aaccf3d;
  }

  .homePageContainer4-headMainCont {
    position: relative;
    padding: 0rem 0rem 0rem 0rem;
  }
}
