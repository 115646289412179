.video-image img {
    display: block;
    width: 80%;
    margin: 20px auto 20px auto;
  }
  
  .home-tab {
    text-shadow: 1px 1px 1px rgb(148 137 137);
    font-size: 25px;
    padding-top: 10px;
    align-items: center;
    font-weight: bold;
    display: block;
    margin: 20px auto;
    width: 80%;
  }
  
  .cst-tabmain {
    width: 80%;
    display: block;
    margin: auto;
  }
  
  .tab-bar {
    padding-bottom: 10px;
  }
  
  .tab-bar ul {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    flex-direction: column;
  }
  
  .tab-bar ul li {
    list-style: none;
    border: 1px solid #000;
    color: #000;
    font-size: 15px;
    border-radius: 10px;
    padding: 15px 10px;
    cursor: pointer;
    font-weight: 700;
    text-align: center;
    width: 100%;
    position: relative;
    margin: 15px 0px;
  }
  
  .tab-active {
    background-color: #3f6b68;
    color: #ffffff !important;
    border: 1px solid #3f6b68 !important;
  }
  
  
  .tab-content-right {
    margin-top: 60px;
  }
  
  /**life support section css***/
  .pagesupport {
    background: #ebe4c8;
    border: 4px solid #ddd5b7;
    padding: 30px 0px;
    margin-bottom: 30px;
  }
  .right-spport p {
    line-height: 30px;
    text-align: justify;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  /**life support section css end**/
  
  .logo-slider {
    padding-top: 10px;
  }
  
  .introlist {
    list-style: none;
    padding-left: 0px;
  }
  
  .introlistp-2 {
    list-style: none;
    padding-left: 0px;
  }
  
  .introlistp-2 li {
    display: flex;
    margin-bottom: 0px;
    font-weight: 500;
  }
  
  .introlist li {
    display: flex;
    margin-bottom: 20px;
    gap: 6px;
  }
  
  .introsimage {
    height: 25px;
    margin-right: 5px;
    width: 25px;
  }
  
  
  /*enroll css start*/
  .enroll-h-pragnancy{
      background: #EBE4C8;
  border: 4px solid #DDD5B7;
  }
  
  .main-plan{
      padding: 20px 0px;
  }
  
  .main-health{
      display: flex;
      justify-content: space-between;
  }
  
  .health-preg-image{
      background: #fff;
      padding: 26px;
      height: 125px;
      border-radius: 50%;
      width: 125px;
  }
  
  .health-preg-image img{
      height: 80px;
      width: 70px;
  }
  
  .health-1 h5{
      text-align: left;
      margin-bottom: 0px;
      font-size: 22px;
      font-weight: 700;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
  }
  .healthdes-para {
      font-size: 14px;
      text-align: center;
      margin-bottom: 5px;
      margin-top: 10px;
      font-weight: 500;
      color: #000;
  }
  /*enroll cess end*/
  
  
  .blsenquirys {
    border: 1px solid #000;
    outline: none;
    padding: 5px 30px;
    background: #fff;
    border-radius: 10px;
    font-size: 14px;
  }
  
  .bls-sb-heading {
    font-weight: 700;
    font-size: 16px;
  }
  
  
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .imgresoiniveslider-news {
    display: block;
    margin: 20px auto 30px auto;
    width: 65%;
    height: 235px;
  }
  
  .sliderheading {
    margin-top: 20px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .first-slider {
    padding: 0px 0px 20px 0px;
  }
  
  .second-slider {
    padding: 0px 0px 20px 0px;
  }
  
  .blstraing-enqi {
    justify-content: left;
    width: 92%;
    margin-left: auto;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 0px;
    grid-gap: 155px;
    gap: 185px !important;
    display: flex;
  }
  
  .plan-detail {
    display: flex;
    justify-content: space-between;
    padding: 10px 8px;
    border-bottom: 1px solid #000;
  }
  
  .plan-slider {
    margin: auto;
    width: 185px;
    height: 225px;
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 1px 0px;
    margin-bottom: 40px;
  }
  
  .cst-paregnacy-row {
    height: 350px;
    align-items: center;
  }
  
  .selecpra-button {
    background: #3F6B68;
    border-radius: 5px;
    border: none;
      color: #fff;
    height: 23px;
    width: 100px;
    margin: 9px auto 0px auto;
    display: block;
    font-size: 11px;
  }
  .ourplan {
      background-color: #fff;
      padding: 15px 0px 60px 0px;
  }
  .oplan-image {
    height: 115px;
  }
  
  .op-main-heading {
    color: #000;
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
  }
  
  .plan-main {
    background: #ffffff;
    box-shadow: 0px 14.1135px 14.1135px rgba(0, 0, 0, 0.25),
      0px 14.1135px 14.1135px rgba(0, 0, 0, 0.25),
      0px 14.1135px 14.1135px rgba(0, 0, 0, 0.25),
      0px 14.1135px 14.1135px rgba(0, 0, 0, 0.25),
      0px 14.1135px 14.1135px rgba(0, 0, 0, 0.25);
    border-radius: 45.8689px;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    align-items: center;
    border: 5px solid #fff;
    cursor: pointer;
      width: 90%;
      margin: auto;
  }
  
  .imgresoiniveslider-partner {
    width: 70%;
    margin: 20px auto 35px auto;
    display: block;
  }
  
  .plan-main:hover {
    border: 5px solid #3F6B68;
  }
  
  .plan-main-h5 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .plan-main-p {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
  }
  
  .left-detail p {
    font-size: 7px;
    margin-bottom: 0px;
    color: #000;
  }
  
  .right-detail p {
    font-size: 9px;
    color: #000;
    margin-bottom: 0px !important;
  }
  
  .plan-heading h4 {
    padding: 15px;
    text-align: center;
    background: #EBE4C8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 14px;
    font-weight: 700;
    color: #000;
  }
  
  .bullet{
    width: 6px;
    height: 6px;
    background-color: #3f6b68;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }

  .slick-prev:before, .slick-next:before {
    color: #3f6b68;
  }
  
  @media (max-width: 1366.9px) {
    .blstraing-enqi {
      justify-content: left;
      width: 92%;
      margin-left: auto;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 0px;
      grid-gap: 250px;
      gap: 185px;
      display: flex;
    }
  }
  
  @media (max-width: 1199.9px) {
    .blstraing-enqi {
      justify-content: left;
      width: 92%;
      margin-left: auto;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 0px;
      grid-gap: 250px;
      gap: 120px;
      display: flex;
    }
  
    .introcontents {
      margin-bottom: 15px;
      font-size: 14px;
    }
  
    .imgresoiniveslider {
      display: block;
      margin: 20px auto 30px auto;
      height: 60px;
    }
  }
  
  @media (max-width: 991.9px) {
    .blstraing-enqi {
      justify-content: left;
      width: 91%;
      margin-left: auto;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 0px;
      grid-gap: 250px;
      gap: 30px;
      display: flex;
    }
  }
  
  @media (max-width: 767.9px) {
    .blstraing-enqi {
      justify-content: left;
      width: 84%;
      margin-left: auto;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 0px;
      grid-gap: 250px;
      grid-gap: 30px;
      gap: 165px;
      display: flex;
    }
  
    .introlist {
      padding-left: 90px;
    }
  }
  
  @media (max-width: 550px) {
    .bls-video video {
      object-fit: cover;
      border-radius: 20px;
      height: 290px;
      width: 330px;
      margin: auto;
      display: block;
    }
  
    .blstraing-enqi {
      justify-content: left;
      width: 84%;
      margin-left: auto;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 0px;
      grid-gap: 250px;
      grid-gap: 30px;
      grid-gap: 165px;
      gap: 90px;
      display: flex;
    }
  
    .blstraing-enqi {
      width: 340px;
      display: flex;
      justify-content: space-around;
      margin: auto;
    }
  
    .tab-bar ul {
      padding-left: 0px;
    }
  
    .tab-bar ul li {
      list-style: none;
      border: 1px solid #000;
      color: #000;
      font-size: 12px;
      border-radius: 10px;
      padding: 15px 10px;
      cursor: pointer;
      font-weight: 700;
      text-align: center;
      width: 340px;
      position: relative;
      margin: 15px auto;
    }
  
    .introlist {
      padding-left: 0px;
      width: 340px;
      margin: auto;
    }
  
    .cst-tabmain {
      width: 100%;
      display: block;
      margin: auto;
    }
  }
  

  
  
    /**faq css**/
      .faq-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0px;
    }
    
    .faq-wrapper-1 h3 {
      font-size: 50px;
      font-weight: 700;
      color: #000;
    }
    
    .faq-wrapper-1 h5 {
      font-size: 20px;
      font-weight: 700;
      color: #000;
    }
    
    .viewf-aq {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      border: 1px solid #ccc;
      text-decoration: none;
      box-shadow: 0px 0px 2px 3px rgb(0 0 0 / 6%);
      padding: 3px 10px;
      width: 150px;
      text-align: center;
      margin: 15px 0;
      background-color: #fff;
    }
    
    .viewf-aq a {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      background-color: #fff;
      text-decoration: none;
    }
    
    .faq-wrapper-2 img {
      margin-top: 0px;
      padding: 20px 0px;
      width: 60%;
      margin-left: auto;
      display: block;
    }
  