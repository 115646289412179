.aboutUsPageContainer1 {
  display: flex;
  padding: 4rem 8rem;
  justify-content: space-between;
}

.aboutUsPageContainer1-img {
  width: 100%;
  border-radius: 0.5rem;
}

.aboutUsPageContainer1-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.aboutUsPageContainer1-right {
  width: 55%;
  height: 270px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.aboutUsPageContainer1-scroll-container {
  display: flex;
  height: 270px;
}

.aboutUsPageContainer1-scroll-bar-box {
  width: 5px;
  background-color: #a4a4a4;
  border-radius: 1rem;
  height: 100%;
  position: relative;
}

.aboutUsPageContainer1-scroll-bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(33%);
  background-color: #2b475a;
  transition: top 0.3s ease;
  border-radius: 1rem;
}

.content {
  margin-left: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aboutUsPageContainer1-text1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  color: #000000;
}

.aboutUsPageContainer1-text2 {
  height: 33%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
  cursor: pointer;
}

@media (1200px <= width <=1300px) {
  .aboutUsPageContainer1-text1 {
    font-size: 16px;
    line-height: 33px;
  }

  .aboutUsPageContainer1-text2 {
    font-size: 18px;
    line-height: 33px;
  }
}

@media (width <= 430px) {
  .aboutUsPageContainer1 {
    display: flex;
    padding: 2rem 1rem;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .aboutUsPageContainer1-img {
    width: 50%;
    border-radius: 0.5rem;
    height: 90px;
    object-fit: contain;
  }

  .aboutUsPageContainer1-div1 {
    display: flex;
    justify-content: space-between;
  }

  .aboutUsPageContainer1-scroll-container {
    display: flex;
    height: 90px;
  }

  .content {
    margin-left: 1rem;
  }

  .aboutUsPageContainer1-text1 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  .aboutUsPageContainer1-text2 {
    font-size: 14px;
    line-height: 22px;
  }
}

.aboutUsPageContainer3 {
  width: 100%;
  position: relative;
  margin: 2rem 0rem;
}

.aboutUsPageContainer3-div {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 0rem;
}

.aboutUsPageContainer3-cont1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 12rem;
}

.aboutUsPageContainer3-h1 {
  /* font-family: Poppins; */
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin: 0;
}

.aboutUsPageContainer3-t1 {
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: 90%;
  margin: 0;
}

.aboutUsPageContainer3-t2 {
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: 100%;
  padding: 0rem 12rem;
}

.aboutUsPageContainer3-t2Btn {
  background-color: #f26828;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #fff;
  margin: 2rem 12rem;
  height: 62px;
  padding: 0rem 40px;
  border-radius: 14px;
}

.aboutUsPageContainer3-alignIcons {
  display: flex;
  justify-content: space-between;
  padding: 0rem 12rem;
  transition: 2s;
  position: relative;
  z-index: 1;
}

.aboutUsPageContainer3-icon {
  width: auto;
  height: auto;
}

.aboutUsPageContainer3-alignIcons-pointer {
  position: absolute;
  left: calc(14%);
  top: calc(-76%);
  height: 85%;
  z-index: 0;
  transition: 2s;
}

.aboutUsPageContainer3-sliderCont {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0rem 12rem;
}

.aboutUsPageContainer3-sliderContImg {
  width: 100%;
}

.aboutUsPageContainer3-sliderDiv {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 15rem;
}

@media (1400px <= width <= 1500px) {
  .aboutUsPageContainer3-div {
    padding: 0rem;
  }

  .aboutUsPageContainer3-cont1 {
    padding: 2rem 10rem;
  }

  .aboutUsPageContainer3-alignIcons {
    padding: 0rem 10rem;
  }

  .aboutUsPageContainer3-sliderCont {
    padding: 2rem 10rem;
  }

  .aboutUsPageContainer3-icon {
    width: 132px;
    height: 132px;
  }

  .aboutUsPageContainer3-alignIcons-pointer {
    left: calc(15%);
    top: calc(-42%);
    height: 65%;
  }

  .aboutUsPageContainer3-sliderDiv {
    padding: 0rem 12rem;
  }

  .aboutUsPageContainer3-h1 {
    font-size: 28px;
    line-height: 40px;
    margin: 0;
  }

  .aboutUsPageContainer3-t1 {
    font-size: 16px;
    line-height: 26px;
  }

  .aboutUsPageContainer3-t2 {
    padding: 0rem 10rem;
    font-size: 16px;
    line-height: 26px;
  }

  .aboutUsPageContainer3-t2Btn {
    margin: 1rem 10rem 2rem 10rem;
    font-size: 16px;
    line-height: 26px;
    height: 52px;
  }
}

@media (1200px <= width <= 1300px) {
  .aboutUsPageContainer3-div {
    padding: 0rem;
  }

  .aboutUsPageContainer3-cont1 {
    padding: 2rem 10rem;
  }

  .aboutUsPageContainer3-alignIcons {
    padding: 0rem 10rem;
  }

  .aboutUsPageContainer3-sliderCont {
    padding: 2rem 10rem;
  }

  .aboutUsPageContainer3-icon {
    width: 132px;
    height: 132px;
  }

  .aboutUsPageContainer3-alignIcons-pointer {
    left: calc(17%);
    top: calc(-47%);
    height: 70%;
  }

  .aboutUsPageContainer3-sliderDiv {
    padding: 0rem 12rem;
  }

  .aboutUsPageContainer3-h1 {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
  }

  .aboutUsPageContainer3-t1 {
    font-size: 14px;
    line-height: 26px;
  }

  .aboutUsPageContainer3-t2 {
    padding: 0rem 10rem;
    font-size: 14px;
    line-height: 26px;
  }

  .aboutUsPageContainer3-t2Btn {
    margin: 1rem 10rem 2rem 10rem;
    height: 48px;
    font-size: 14px;
    line-height: 26px;
  }
}
.aboutUsPageContainer3-sliderContMain {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.aboutUsPageContainer3-sliderDiv-h {
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.aboutUsPageContainer3-sliderDiv-h1 {
  /* font-family: Poppins; */
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.aboutUsPageContainer3-sliderDiv-t {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.aboutUsPageContainer3-sliderDiv-d {
  /* font-family: Poppins; */
  font-size: 19px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.aboutUsPageContainer3-arrow1 {
  position: absolute;
  left: 3rem;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  rotate: 180deg;
  background-color: #f26828;
  color: #fff;
  top: calc(42%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.aboutUsPageContainer3-arrow2 {
  position: absolute;
  right: 3rem;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  background-color: #f26828;
  color: #fff;
  top: calc(42%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 430px) {
  .aboutUsPageContainer3 {
    width: 100%;
    position: relative;
    margin: 0rem;
  }

  .aboutUsPageContainer3-div {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    top: 0;
    left: 0;
  }

  .aboutUsPageContainer3-cont1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 8rem;
  }

  .aboutUsPageContainer3-h1 {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    margin: 0;
  }

  .aboutUsPageContainer3-t1 {
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    width: 84%;
    margin: 0;
  }

  .aboutUsPageContainer3-alignIcons {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem;
    transition: 2s;
    position: relative;
    z-index: 1;
  }

  .aboutUsPageContainer3-icon {
    width: 50px;
    height: 50px;
  }

  .aboutUsPageContainer3-alignIcons-pointer {
    position: absolute;
    left: calc(5%);
    top: calc(-16%);
    z-index: 0;
    transition: 2s;
    height: 32%;
  }

  .aboutUsPageContainer3-sliderContMain {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 0rem;
  }

  .aboutUsPageContainer3-sliderCont {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f26828;
    width: 100%;
    height: 11.5rem;
    box-sizing: border-box;
    border-radius: 1rem;
    flex-direction: column;
    padding: 1rem;
  }

  .aboutUsPageContainer3-sliderDiv {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* left: calc(10%); */
  }

  .aboutUsPageContainer3-sliderDivM {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .aboutUsPageContainer3-sliderDiv-h {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .aboutUsPageContainer3-sliderDiv-h1 {
    /* font-family: Poppins; */
    font-size: 6px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin: 4px 0;
  }

  .aboutUsPageContainer3-sliderDiv-t {
    /* font-family: Poppins; */
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .aboutUsPageContainer3-sliderDiv-d {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .aboutUsPageContainer3-arrow1 {
    position: absolute;
    left: 3rem;
    border-radius: 100%;
    padding: 2px;
    rotate: 180deg;
    background-color: #f26828;
    color: #fff;
    top: calc(42%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .aboutUsPageContainer3-arrow2 {
    position: absolute;
    right: 3rem;
    border-radius: 100%;
    padding: 2px;
    background-color: #f26828;
    color: #fff;
    top: calc(42%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .aboutUsPageContainer3-M {
    position: absolute;
    top: 0;
    padding: 5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
  }

  .aboutUsPage-MobileIconsCOntainer {
    position: relative;
  }

  .aboutUsPageContainer3-t2 {
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    width: 100%;
    padding: 0rem 0rem;
  }

  .aboutUsPageContainer3-t2Btn {
    margin: 0rem;
    font-size: 12px;
    line-height: 18px;
    padding: 0rem 14px;
    height: 35px;
    border-radius: 4px;
  }
}
