.mequipPageContainer3 {
  padding: 2rem 12rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.mequipPageContainer3-card {
  width: calc(20%);
  gap: 1rem;
  height: 23rem;
}

.mequipPageContainer3-div {
  width: 80%;
}

.mequipPageContainer3-cardHead {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #000;
  margin: 12px 0px 4px 0px;
  width: 100%;
  text-transform: capitalize;
}

.mequipPageContainer3-cardText {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #4d4d4d;
  width: 100%;
}

@media (1400px <= width <=1550px) {
  .mequipPageContainer3 {
    padding: 2rem 10rem;
    column-gap: 3rem;
  }

  .mequipPageContainer3-div {
    width: 90%;
  }

  .mequipPageContainer3-card {
    width: calc(20%);
    gap: 1rem;
    height: 23rem;
  }

  .mequipPageContainer3-cardHead {
    font-size: 20px;
    line-height: 30px;
    margin: 12px 0px 4px 0px;
  }

  .mequipPageContainer3-card {
    height: 20rem;
  }

  .mequipPageContainer3-cardText {
    font-size: 14px;
    line-height: 27px;
  }
}

@media (1200px <= width <=1300px) {
  .mequipPageContainer3 {
    padding: 2rem 10rem;
    column-gap: 3rem;
  }
  
  .mequipPageContainer3-card {
    height: 20rem;
  }

  .mequipPageContainer3-div {
    width: 100%;
  }

  .mequipPageContainer3-cardHead {
    font-size: 20px;
    line-height: 30px;
    margin: 12px 0px 4px 0px;
  }

  .mequipPageContainer3-cardText {
    font-size: 14px;
    line-height: 27px;
  }
}

.medi-card {
  width: 25%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1rem;
}

.medi-card-div1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: calc(40%);
}

@media (1380px <= width <=1500px) {
  .medi-card {
    width: 32%;
  }

  .medi-card-div1 {
    top: calc(40%);
  }
}

@media (1180px <= width <=1300px) {
  .medi-card {
    width: 30%;
  }

  .medi-card-div1 {
    top: calc(37%);
  }
}

@media screen and (max-width: 430px) {
  .medi-card {
    width: 46.5%;
  }

  .medi-card-div1 {
    top: calc(27%);
  }

  .mequipPageContainer3 {
    padding: 2rem 1rem;
    column-gap: 1rem;
    row-gap: 2rem;
  }
  .mequipPageContainer3-card {
    width: 45%;
    height: auto;
  }

  .mequipPageContainer3-div {
    width: 100%;
  }

  .mequipPageContainer3-cardHead {
    font-size: 16px;
    line-height: 24px;
  }

  .mequipPageContainer3-cardText {
    font-size: 12px;
    line-height: 18px;
  }
}
