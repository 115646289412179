.physioPageContainer3-cardImg {
  position: absolute;
  top: 0;
  height: auto;
  width: auto;
}

.physioPageContainer3-cardText2 {
  /* font-family: Poppins; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.21804547309875488px;
  text-align: center;
  color: #000000;
  /* text-transform: capitalize; */
  padding: 0rem 1rem;
  position: absolute;
  top: calc(53%);
  width: 90%;
}

.abcbccb {
  width: 100%;
}

.physioPageContainer4 {
  padding: 2rem 10rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0rem;
  justify-content: space-between;
  align-items: center;
}

.physioPageContainer4-card {
  width: calc(22.5%);
  height: 32rem;
  z-index: 2;
  box-sizing: border-box;
}

.physioPageContainer4-cardHead {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.09977559000253677px;
  text-align: left;
  color: #000000;
  text-transform: capitalize;
  width: calc(100%);
  margin: 1rem 0rem;
  box-sizing: border-box;
}

.physioPageContainer4-cardText {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.19834476709365845px;
  text-align: left;
  color: #4d4d4d;
  width: 95%;
}

@media (width <= 1600px) {
  .physioPageContainer4 {
    padding: 2rem 10rem;
  }

  .physioPageContainer4-card {
    height: 25rem;
  }

  .physioPageContainer4-cardHead {
    font-size: 17px;
    line-height: 27px;
  }

  .physioPageContainer4-cardText {
    font-size: 14px;
    line-height: 22px;
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .physioPageContainer3-cardImg {
    position: absolute;
    top: 0;
    height: auto;
    width: 25%;
  }

  .physioPageContainer3-cardText2 {
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.21804547309875488px;
    text-align: center;
    color: #000000;
    /* text-transform: capitalize; */
    padding: 0rem 1rem;
    position: absolute;
    top: calc(48%);
    width: 96%;
  }

  .physioPageContainer4 {
    padding: 1rem 1rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0rem;
    row-gap: 2rem;
    justify-content: space-between;
    align-items: center;
  }

  .physioPageContainer4-card {
    width: calc(46%);
    height: 18rem;
    z-index: 2;
  }

  .physioPageContainer4-cardHead {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }

  .physioPageContainer4-cardText {
    font-size: 10px;
    line-height: 18px;
  }
}
