.faqs {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
  }
  
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
  }
  
  .faqs .faq .faq-question::after {
    content: url("https://www.iconpacks.net/icons/2/free-arrow-down-icon-3101-thumb.png");
    position: absolute;
    top: -21%;
    right: 9px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transform: scale(0.04);
    transition: all 0.2s ease;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
  }
  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
  }
  