.mobileHeaderBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px double #7e97c5;
  padding: 6px 16px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.homePageMEnuImg {
  cursor: pointer;
}

.mobileHeaderBtn:hover {
  opacity: 0.8;
}

.optionMHeaderContainer {
  overflow: hidden;
  padding: 1rem 0rem 0rem 0rem;
  background-color: #215071;
  width: 100%;
}

.optionMHeader {
  display: flex;
  width: 300%;
  gap: 2rem;
  animation: scroll 20s linear infinite;
}

.optionMHeaderLabel {
  font-size: 0.8rem;
  cursor: pointer;
  letter-spacing: 0.2px;
  opacity: 1;
  color: #ffffff;
}

@keyframes scroll {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(-70%);
  }
}

.mobileheaderContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: transparent;
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 0rem 1rem;
}
