.main-heading-astrology {
    font-size: 30px;
    text-align: center;
    color: #3f6b68;
    font-weight: 900;
    margin: 30px 0px;
  }
  
  .safety-para-astrology {
    font-size: 15px;
    color: #000;
    font-weight: 600;
  }
  
  .spacing-astrology {
    margin: 30px 0px;
  }
  
  .engage-main-astrology {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 0px;
  }
  
  .engage-main-astrology h6 {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    width: 60%;
    font-weight: 600;
  }
  
  .engageimg-div-astrology {
    background: #ebe4c8;
    padding: 26px;
    height: 125px;
    border-radius: 50%;
    width: 125px;
    margin-bottom: 30px;
    border: 2px solid #ddd5b7;
  }
  
  .aidkit-1-astrology{
    margin: 50px 0;
  }
  .aidkit-2-astrology{
    margin: 10px 0 50px 0px;
  }
  
  .engageimg-div-astrology img {
    height: 75px;
    margin: auto;
    display: block;
  }
  
  .roadsafety-list-astrology li {
    font-size: 15px;
    font-weight: 600;
    line-height: 36px;
  }
  
  .booknewawssion {
    background: #ebe4c8;
    border: 4px solid #ddd5b7;
  }
  .sessioncall-btn {
    display: block;
    margin: auto;
    height: 50px;
    width: 175px;
    border-radius: 10px;
    background-color: #3f6b68;
    border: 2px solid #3f6b68;
    color: #fff;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .sessioncall-para {
    color: #888888;
    text-align: center;
    font-size: 18px;
    margin: 20px 0px;
    font-weight: 600;
  }
  
  .sessioncall-heading-astrology {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 10px 0px 30px 0px;
  }
  
  /**life support section css***/
  .pagesupport-astrology{
    background: #EBE4C8;
    border: 4px solid #DDD5B7;
    padding: 30px 0px;
    margin-bottom: 30px;
  }
  .left-spport-astrology img{
    width: 35%;
    display: block;
    margin: auto;
  }
  .right-spport-astrology p{
    line-height: 30px;
    text-align: justify;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .safety-paraul-astrology li {
    font-size: 15px;
    color: #000;
    font-weight: 600;
    line-height: 30px;
  }
  .roadmap-fristkitastr-astrology {
    width: 25%;
    margin: 50px auto;
    display: block;
  }
  /**life support section css end**/
  
  .roadmap-fristkit-astrology{
    width: 80%;
    margin: 50px auto;
    display: block;
  }