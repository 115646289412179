.our-team-header {
    font-size: 35px;
    text-align: center;
    font-weight: 700;
    color: #000;
    padding-top: 60px;
    padding-bottom: 20px;
}

.box-title {
    text-decoration: solid;
    text-align: center;
    padding: 20px;
    padding-top: 30px;
}
.row-team::after {
    content: "";
    clear: both;
    display: table;
}

.row-team {
    text-align: center;
    display: flexbox;
}

.team-image {
    width: 100%;
    height: 75%;
}

.caption {
    text-justify:auto;
    color: rgba(0, 0, 0, 0.6);
    text-size-adjust: 16px;
    line-height: 1;
}

.box-1-team {
    padding-top: 15px;
    transition: box-shadow .6s;
    width: 280px;
    height: 400px;
    margin: 40px;
    padding-bottom: 50px;
    border-radius:10px;
    border: 1px solid #ccc;
    background: #fff;
    float: left;
  }
  .box-1-team:hover {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.642); 
  }

.box-team {
    padding-top: 15px;
    transition: box-shadow .6s;
    width: 280px;
    height: 360px;
    margin: 40px;
    border-radius:10px;
    border: 1px solid #ccc;
    background: #fff;
    float: left;
  }
  .box-team:hover {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.642); 
  }

.row-1-team {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.row-1-team::after {
    content: "";
    clear: both;
    display: table;
}

  @media screen and (max-width: 680px) {
    .row-1-team {
        text-align: center;
        margin-left: 0%;
        display: flexbox;
    }
    .row-1-team::after {
        content: "";
        clear: both;
        display: table;
    }
    .row-team {
        text-align: center;
        margin-left: 0%;
        display: flexbox;
    }
    .row-team::after {
        content: "";
        clear: both;
        display: table;
    }
  }