.safetyTrainingContainer {
  padding: 3rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.safetyTrainingContainer-div {
  width: calc(100%);
}

.safetyTrainingContainer-Head1 {
  /* font-family: Poppins; */
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  color: #f26828;
}

.safetyTrainingContainer-Head2 {
  /* font-family: Poppins; */
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  color: #000000;
  padding: 0px 0px 1rem 0px;
}

.safetyTrainingContainer-Text {
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  color: #000000;
}

@media (1400px <= width <= 1500px) {
  .safetyTrainingContainer-Head1 {
    font-size: 32px;
    line-height: 45px;
  }

  .safetyTrainingContainer-Head2 {
    font-size: 32px;
    line-height: 45px;
  }

  .safetyTrainingContainer-Text {
    /* font-family: Poppins; */
    font-size: 18px;
    line-height: 33px;
  }
}

@media (1200px <= width <= 1300px) {
  .safetyTrainingContainer-Head1 {
    font-size: 30px;
    line-height: 38px;
  }

  .safetyTrainingContainer-Head2 {
    font-size: 30px;
    line-height: 38px;
  }

  .safetyTrainingContainer-Text {
    /* font-family: Poppins; */
    font-size: 16px;
    line-height: 28px;
  }
}

@media (600px <= width <=1000px) {
  .safetyTrainingContainer-Head1 {
    /* font-family: Poppins; */
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #f26828;
  }

  .safetyTrainingContainer-Head2 {
    /* font-family: Poppins; */
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #000000;
    padding: 0px 0px 1rem 0px;
  }

  .safetyTrainingContainer-Text {
    /* font-family: Poppins; */
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    color: #000000;
  }

  .safetyTrainingContainer1-Div {
    width: 100%;
    border: 0.94px solid #215071;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    height: 340px !important;
  }

  .safetyTrainingContainer1-Img {
    margin: auto;
    max-width: 100%;
    width: 30% !important;
  }

  .safetyTrainingContainer1-Text {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 500;
    line-height: 27px;
    text-align: justified;
    color: #000000;
    width: calc(48%);
  }

  .homePageContainer4 {
    padding: 0px 72px;
  }

  .homePageContainer4-arrow1 {
    display: none;
  }
  .homePageContainer4-arrow2 {
    display: none;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 9px;
    font-weight: 400;
    line-height: 16px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }

  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 80%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 280px;
    padding: 2rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }

  .safetyTrainingContainer4-text {
    font-family: Poppins;
    font-size: 9px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }
}

.safetyTrainingContainer1 {
  position: relative;
}

.safetyTrainingContainer1-Div {
  width: 100%;
  border: 0.94px solid #215071;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  height: 700px;
}

@media (1200px <= width <= 1500px) {
  .safetyTrainingContainer1-Div {
    width: 100%;
    border: 0.94px solid #215071;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    height: 500px;
  }
}

.safetyTrainingContainer1-Div1 {
  width: calc(48%);
}

.safetyTrainingContainer1-Img {
  margin: auto;
}

.safetyTrainingContainer1-Text {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: justified;
  color: #000000;
  width: calc(48%);
}

@media (1920px>= width >= 1600px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 60%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 500px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

@media (1599px>= width >= 1440px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 70%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 500px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

@media (1400px>= width >= 1200px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 80%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 500px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }
  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

@media (1199px>= width >= 1000px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 90%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 425px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

@media (1920px>= width >= 1600px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 60%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 500px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

@media (1599px>= width >= 1440px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 70%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 500px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

@media (1400px>= width >= 1200px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 80%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 500px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }
  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

@media (1199px>= width >= 1000px) {
  .safetyTrainingContainer2 {
    margin: 2rem auto;
    width: 90%;
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    height: 425px;
    padding: 3rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: justify;
    color: #000000;
    margin: 0;
  }
}

.safetyTrainingContainer2-div1 {
  width: calc(46%);
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.safetyTrainingContainer2-div2 {
  width: calc(46%);
  height: 100% !important;
}

.safetyTrainingContainer2-Img {
  width: 100%;
}

.safetyTrainingContainer3 {
  padding: 2rem 12rem;
}

.safetyTrainingContainer3-Img {
  width: calc(80%);
  display: block;
  margin: auto;
}

.safetyTrainingContainer-btn {
  width: 180px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f26828;
  /* font-family: Poppins; */
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
}

.safetyTrainingContainer4 {
  display: flex;
  justify-content: space-between;
  padding: 6rem 20rem 2rem 20rem;
  align-items: flex-start;
}

@media (1200px <= width <= 1500px) {
  .safetyTrainingContainer4 {
    padding: 6rem 10rem 2rem 10rem;
  }
}

.safetyTrainingContainer4-div {
  width: calc(45%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.safetyTrainingContainer4-text {
  font-family: Poppins;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 430px) {
  .safetyTrainingContainer-btn {
    width: 128px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f26828;
    /* font-family: Poppins; */
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
  }

  .safetyTrainingContainer {
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .safetyTrainingContainer-div {
    width: calc(100%);
  }

  .safetyTrainingContainer-Head1 {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
    color: #f26828;
    margin: 0;
  }

  .safetyTrainingContainer-Head2 {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
    color: #000000;
    padding: 0px 0px 1rem 0px;
    margin: 0;
  }

  .safetyTrainingContainer-Text {
    /* font-family: Poppins; */
    font-size: 10px;
    font-style: italic;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #000000;
    margin: 0;
  }

  .safetyTrainingContainer1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .safetyTrainingContainer1-Div {
    width: 90%;
    border: 0.94px solid #215071;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    height: 200px;
  }

  .safetyTrainingContainer1-Div1 {
    width: calc(48%);
  }

  .safetyTrainingContainer1-Img {
    margin: auto;
  }

  .safetyTrainingContainer1-Text {
    /* font-family: Poppins; */
    font-size: 7px;
    font-weight: 500;
    line-height: 16px;
    text-align: justified;
    color: #000000;
    width: calc(48%);
  }

  .safetyTrainingContainer2 {
    margin: 2rem 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fffefe;
    box-sizing: border-box;
    padding: 1rem;
    box-shadow: 0px 3px 40px 0px #00000017;
    border-radius: 1rem;
    height: auto;
  }

  .safetyTrainingContainer2-div1 {
    width: calc(46%);
  }

  .safetyTrainingContainer2-div2 {
    width: calc(46%);
  }

  .safetyTrainingContainer2-Img {
    width: 100%;
  }

  .safetyTrainingContainer2-Head {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 6.pregnancyCarePageContainer3-cardText100;
    line-height: 20px;
    text-align: left;
    color: #000;
  }

  .safetyTrainingContainer2-Text {
    /* font-family: Poppins; */
    font-size: 8px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    color: #000000;
    margin: 0;
  }

  .safetyTrainingContainer3 {
    padding: 2rem 1rem;
  }

  .safetyTrainingContainer3-Img {
    width: 100%;
    display: block;
    margin: auto;
  }

  .safetyTrainingContainer4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
    align-items: flex-start;
  }

  .safetyTrainingContainer4-div {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .safetyTrainingContainer4-text {
    font-family: Poppins;
    font-size: 9px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
    text-align: justify;
    color: #000000;
  }
}
