.pregnancyCarePageContainer1 {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

/* .pregnancyCarePageImg1 {
  width: 100%;
  height: 42rem;
} */

@media only screen and (max-width: 1280px) {
  .pregnancyCarePageImg1 {
    width: 100%;
    height: 30rem;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .pregnancyCarePageImg1 {
    width: 100%;
    height: 44rem;
  }
}

.pregnancyCarePageContainer1Content {
  position: absolute;
  top: 0px;
  height: 100%;
  padding: 0rem 0rem 4rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 0;
  width: 100%;
  background-image: linear-gradient(
    348.7deg,
    #000000 22.56%,
    rgba(0, 0, 0, 0) 75.96%
  );
}

.pregnancyCarePageContainer1Content-Head {
  color: #ffffff;
  font-weight: 700;
  font-size: 50px;
}

.pregnancyCarePageContainer1Content-text {
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  letter-spacing: 0.2px;
  padding: 16px 0px;
}

.pregnancyCarePageContainer1Content-btn {
  background-color: #f26828;
  color: #ffffff;
  width: 15rem;
  height: 4rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  opacity: 1;
  font-weight: 600;
  cursor: pointer;
}

.pregnancyCarePageContainer1Content-btn:hover {
  opacity: 0.8;
}

.pregnancyCarePageContainer-heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  color: #000000;
  padding: 2rem 0rem;
}

.pregnancyCarePageContainer2 {
  position: relative;
}

.pregnancyCarePageContainer2-div {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  top: 0;
  box-sizing: border-box;
}

.pregnancyCarePageContainer2-div1 {
  position: relative;
}

.pregnancyCarePageContainer2-div2 {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: 3rem;
}

.pregnancyCarePageContainer2-div-h {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.19658511877059937px;
  text-align: left;
  color: #ffffff;
}

.pregnancyCarePageContainer2-div-t {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.19658511877059937px;
  text-align: left;
  color: #ffffff;
  width: 80%;
  padding: 1rem 0rem;
}

.pregnancyCarePageContainer2-div-t1 {
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.22962571680545807px;
  text-align: left;
  color: #ffffff;
  padding: 0px 0px 8px 0px;
}

.pregnancyCarePageContainer2-div-t2 {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.22962571680545807px;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}

.pregnancyCarePageContainer2-div-t3 {
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: italic;
  font-weight: 275;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0px 7rem 0px 0px;
}

.pregnancyCarePageContainer2-aligncheckbox {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pregnancyCarePageContainer2-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0rem;
}

.pregnancyCarePageContainer2-btn1 {
  border: 0.6px solid #ffffff;
  /* font-family: Poppins; */
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0.2rem 3rem;
  border-radius: 0.5rem;
}

.pregnancyCarePageContainer2-btn2 {
  background-color: #f26828;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0.2rem 3rem;
  border-radius: 0.5rem;
}

.pregnancyCarePageContainer2-btn3 {
  border: 0.6px solid #ffffff;
  /* font-family: Poppins; */
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0.2rem 2rem;
  border-radius: 0.5rem;
}

.pregnancyCarePageContainer-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem;
}

.pregnancyCarePageContainer-toggle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf7ff;
  padding: 0.5rem 0.5rem;
  border-radius: 2rem;
  width: auto;
}

.pregnancyCarePageContainer-toggleActive {
  background-color: #215071;
  width: 150px;
  height: 2.5rem;
  /* font-family: Poppins; */
  font-size: 18.97px;
  font-weight: 600;
  line-height: 28.46px;
  text-align: center;
  color: #fff;
  border-radius: 1rem;
  cursor: pointer;
  opacity: 1;
}

.pregnancyCarePageContainer-toggleUnActive {
  background-color: transparent;
  width: 150px;
  height: 2.5rem;
  /* font-family: Poppins; */
  font-size: 18.97px;
  font-weight: 600;
  line-height: 28.46px;
  text-align: center;
  color: #215071;
  border-radius: 1rem;
  cursor: pointer;
  opacity: 1;
}

.pregnancyCarePageContainer-toggleActive:hover {
  opacity: 0.8;
}

.pregnancyCarePageContainer-toggleUnActive:hover {
  opacity: 0.8;
}

.pregnancyCarePageContainer3 {
  padding: 3rem 20rem 3rem 20rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  column-gap: 5rem;
  row-gap: 6rem;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  position: relative;
}

.pregnancyCarePageContainer3-card {
  width: 100%;
  height: 26rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 3;
  opacity: 1;
}

.pregnancyCarePageContainer3-cardDiv2 {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.pregnancyCarePageContainer3-cardImg {
  display: block;
  margin: 0rem auto 2rem auto;
  width: auto;
  height: auto;
}

.pregnancyCarePageContainer3-cardText1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.13966697454452515px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  z-index: 1;
  padding: 1rem 2.5rem 0rem 2.5rem;
}

.pregnancyCarePageContainer3-cardHead {
  margin: 0;
  padding: 2rem 0rem 0rem 0rem;
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.13966697454452515px;
  text-align: center;
  color: #000000;
  box-sizing: border-box;
  width: 80%;
  text-transform: capitalize;
  z-index: 1;
}

@media (1400px <= width <= 1500px) {
  .pregnancyCarePageContainer3 {
    padding: 3rem 10rem 3rem 10rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 3rem;
    row-gap: 6rem;
  }

  .pregnancyCarePageContainer3-cardImg {
    margin: auto;
    width: 100px;
    height: 100px;
  }

  .pregnancyCarePageContainer3-cardHead {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

  .pregnancyCarePageContainer3-cardText1 {
    width: 100%;
    padding: 1rem 2.5rem 0rem 2.5rem;
    font-size: 16px;
  }
}

@media (1200px <= width <= 1300px) {
  .pregnancyCarePageContainer3 {
    padding: 3rem 8rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 3rem;
    row-gap: 4rem;
  }

  .pregnancyCarePageContainer3-cardImg {
    margin: auto;
    width: 84px;
    height: 84px;
  }

  .pregnancyCarePageContainer3-cardHead {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    width: 70%;
  }

  .pregnancyCarePageContainer3-cardText1 {
    width: 100%;
    padding: 1rem 2.5rem 0rem 2.5rem;
    font-size: 14px;
  }
}

.pregnancyCarePageContainer3-cardB1 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(12.5%);
  left: calc(25%);
}

.pregnancyCarePageContainer3-cardB2 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(12.5%);
  right: calc(25%);
  rotate: 90deg;
}

.pregnancyCarePageContainer3-cardB3 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(47%);
  right: calc(25%);
  rotate: 180deg;
}

.pregnancyCarePageContainer3-cardB4 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(47%);
  left: calc(25%);
  rotate: 270deg;
}

.pregnancyCarePageContainer4 {
  margin: 4rem;
}

.pregnancyCarePageContainer5 {
  margin: 4rem 0rem;
  position: relative;
  z-index: 0;
}

.pregnancyCarePageContainer5-div {
  position: absolute;
  top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pregnancyCarePageContainer5-img {
  display: block;
  margin: auto;
}

.pregnancyCarePageContainer5-div1 {
  position: absolute;
  width: calc(12%);
  left: calc(5.9%);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pregnancyCarePageContainer5-div11 {
  height: 100%;
  width: 45%;
  cursor: pointer;
}

.pregnancyCarePageContainer5-div2 {
  position: absolute;
  width: calc(76%);
  height: 100%;
  right: calc(6%);
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .pregnancyCarePageContainer1 {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .pregnancyCarePageImg1 {
    width: 100%;
  }

  .pregnancyCarePageContainer1Content {
    position: absolute;
    top: 0px;
    height: 100%;
    padding: 0rem 0rem 4rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 0;
    width: 100%;
    background-image: linear-gradient(
      348.7deg,
      #000000 22.56%,
      rgba(0, 0, 0, 0) 75.96%
    );
  }

  .pregnancyCarePageContainer1Content-Head {
    color: #ffffff;
    font-weight: 700;
  }

  .pregnancyCarePageContainer1Content-text {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.2px;
    padding: 16px 0px;
  }

  .pregnancyCarePageContainer1Content-btn {
    background-color: #f26828;
    color: #ffffff;
    width: 9rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    opacity: 1;
    cursor: pointer;
  }

  .pregnancyCarePageContainer1Content-btn:hover {
    opacity: 0.8;
  }

  .pregnancyCarePageContainer-heading {
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    color: #000000;
    padding: 2rem 0rem;
  }

  .pregnancyCarePageContainer2 {
    position: relative;
  }

  .pregnancyCarePageContainer2-div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    top: 0;
    box-sizing: border-box;
  }

  .pregnancyCarePageContainer2-div1 {
    position: relative;
  }

  .pregnancyCarePageContainer2-div2 {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 3rem;
  }

  .pregnancyCarePageContainer2-div-h {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
  }

  .pregnancyCarePageContainer2-div-t {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
    width: 80%;
    padding: 1rem 0rem;
  }

  .pregnancyCarePageContainer2-div-t1 {
    /* font-family: Poppins; */
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    padding: 0px 0px 8px 0px;
  }

  .pregnancyCarePageContainer2-div-t2 {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
  }

  .pregnancyCarePageContainer2-div-t3 {
    /* font-family: Poppins; */
    font-size: 14px;
    font-style: italic;
    font-weight: 275;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0px 7rem 0px 0px;
  }

  .pregnancyCarePageContainer2-aligncheckbox {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .pregnancyCarePageContainer2-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0rem;
  }

  .pregnancyCarePageContainer2-btn1 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0.2rem 3rem;
    border-radius: 0.5rem;
  }

  .pregnancyCarePageContainer2-btn2 {
    background-color: #f26828;
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0.2rem 3rem;
    border-radius: 0.5rem;
  }

  .pregnancyCarePageContainer2-btn3 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0.2rem 2rem;
    border-radius: 0.5rem;
  }

  .pregnancyCarePageContainer-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0rem;
  }

  .pregnancyCarePageContainer-toggle1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebf7ff;
    padding: 0.5rem 0.5rem;
    border-radius: 2rem;
    width: auto;
  }

  .pregnancyCarePageContainer-toggleActive {
    background-color: #215071;
    width: 150px;
    height: 2.5rem;
    /* font-family: Poppins; */
    font-size: 18.97px;
    font-weight: 600;
    line-height: 28.46px;
    text-align: center;
    color: #fff;
    border-radius: 1rem;
    cursor: pointer;
    opacity: 1;
  }

  .pregnancyCarePageContainer-toggleUnActive {
    background-color: transparent;
    width: 150px;
    height: 2.5rem;
    /* font-family: Poppins; */
    font-size: 18.97px;
    font-weight: 600;
    line-height: 28.46px;
    text-align: center;
    color: #215071;
    border-radius: 1rem;
    cursor: pointer;
    opacity: 1;
  }

  .pregnancyCarePageContainer-toggleActive:hover {
    opacity: 0.8;
  }

  .pregnancyCarePageContainer-toggleUnActive:hover {
    opacity: 0.8;
  }

  .pregnancyCarePageContainer3 {
    padding: 1rem 1rem 3rem 1rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    position: relative;
  }

  .pregnancyCarePageContainer3-card {
    width: calc(45%);
    box-shadow: none;
    height: auto;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    gap: 0rem;
  }

  .pregnancyCarePageContainer3-cardDiv2 {
    height: 13rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .pregnancyCarePageContainer3-cardImg {
    width: 65px;
    height: 65px;
    margin: 0rem auto 0.5rem auto;
  }

  .pregnancyCarePageContainer3-cardText1 {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #4d4d4d;
    width: 70%;
    z-index: 1;
    padding: 1rem 0rem 0rem 0rem;
  }

  .pregnancyCarePageContainer3-cardHead {
    margin: 0;
    padding: 2rem 0rem 0rem 0rem;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #000000;
    box-sizing: border-box;
    width: 80%;
    text-transform: capitalize;
    z-index: 1;
  }

  .pregnancyCarePageContainer3-cardB1 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(12.5%);
    left: calc(25%);
  }

  .pregnancyCarePageContainer3-cardB2 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(12.5%);
    right: calc(25%);
    rotate: 90deg;
  }

  .pregnancyCarePageContainer3-cardB3 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(47%);
    right: calc(25%);
    rotate: 180deg;
  }

  .pregnancyCarePageContainer3-cardB4 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(47%);
    left: calc(25%);
    rotate: 270deg;
  }

  .pregnancyCarePageContainer4 {
    margin: 4rem;
  }

  .pregnancyCarePageContainer5 {
    margin: 4rem 0rem;
    position: relative;
    z-index: 0;
  }

  .pregnancyCarePageContainer5-div {
    position: absolute;
    top: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pregnancyCarePageContainer5-img {
    display: block;
    margin: auto;
  }

  .pregnancyCarePageContainer5-div1 {
    position: absolute;
    width: calc(12%);
    left: calc(5.9%);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pregnancyCarePageContainer5-div11 {
    height: 100%;
    width: 45%;
    cursor: pointer;
  }

  .pregnancyCarePageContainer5-div2 {
    position: absolute;
    width: calc(76%);
    height: 100%;
    right: calc(6%);
    cursor: pointer;
  }
}
