.mobileFooterContainer {
  box-sizing: border-box;
  background-color: #f5fbff;
  padding: 1rem 1.3rem;
}

.mobileFooterContainerDiv1 {
  display: flex;
  gap: calc(15%);
  box-sizing: border-box;
}

.mobileFooterContainerDiv1-h {
  padding: 0px 0px 4px 0px;
  font-size: 21px;
  font-weight: 600;
}

.mobileFooter-alignIcons {
  display: flex;
}

.mobileFooterContainerDiv1-p {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  padding: 0px 0px 4px 0px;
  cursor: pointer;
  opacity: 1;
}

.mobileFooterContainerDiv1-p:hover {
  opacity: 0.8;
}

.mobileFooterContainerDiv2 {
  width: calc(40%);
}

.mobileFooterContainerDiv2-imgDiv {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.mobileFooterImgs {
  width: calc(30%);
  height: 2rem;
}

.mobileFooterContainerDiv2-rightDivMain {
  width: 100%;
  display: flex;
  /* height: 300px; */
  justify-content: space-between;
}

.mobileFooterContainerDiv2-rightDiv {
  width: 100%;
  display: flex;
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
}

.mobileFooterContainerDiv2-div1 {
  width: 100%;
  display: flex;
  gap: 0.8rem;
  padding: 1rem 1.3rem;
  background-color: #f5fbff;
}

.mobileFooterContainerIcons {
  padding: 4px;
  background-color: #000;
  border-radius: 100%;
  font-size: 0.5rem;
  cursor: pointer;
  opacity: 1;
}

.mobileFooterContainerIcons:hover {
  opacity: 0.8;
}

.mobileFooterContainerDiv2-div2 {
  width: 100%;
  display: flex;
  /* gap: 0.5rem; */
  /* padding: 0.5rem 0rem; */
}

.mobileFooterMap {
  box-shadow: 0px 0px 25px 2px #00000012;
  border-radius: 0.5rem;
  background-color: #fafafa;
  width: 100%;
  height: 51px;
  margin-bottom: 16px;
}

.mobileFooterAddress {
  display: flex;
  flex-direction: column;
}

.mobileFooterAddress-p {
  margin: 0;
  font-weight: 500;
  font-size: 0.5rem;
  padding: 0px 0px 0px 0px;
  cursor: pointer;
  opacity: 1;
}

.mobileFooterAddress-p:hover {
  opacity: 0.8;
}

.mobileFooterContainerLst {
  /* padding: 0px 48px 16px 48px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5fbff;
  gap: 1rem;
  padding: 0.5rem 1.3rem;
  position: relative;
}
