.blogPageContainer {
  padding: 3rem 6rem 2rem 6rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.blogPageContainer2 {
  padding: 0rem 6rem 3rem 6rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  column-gap: calc(2%);
  row-gap: 2rem;
  justify-content: flex-start;
}

.blogPageContainer2-div {
  width: calc(23.5%);
  height: 384px;
  position: relative;
}

.blogPageContainer-divL {
  width: calc(48.5%);
  position: relative;
  height: 800px;
}

.blog-img {
  width: 100%;
  border-radius: 1rem;
  height: 100%;
}

.blog-Btn {
  background-color: #f26828;
  /* font-family: Poppins; */
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding: 0.2rem 2.5rem;
  border-radius: 0.35rem;
  width: fit-content;
  cursor: pointer;
}

.blog-content {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  width: 90%;
  margin: 0;
}

.blog-content1 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ababab;
  width: 90%;
  margin: 0;
}

.blogPageContainer-divL-content {
  position: absolute;
  bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
}

.blogPageContainer-divR {
  width: calc(49%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 800px;
}

.blogPageContainer-divR1 {
  width: 100%;
  height: 48%;
  display: flex;
  justify-content: space-between;
}

.blogPageContainer-divR-divs {
  width: calc(48%);
  position: relative;
  height: calc(100%);
}

.blogPageContainer-divR-content {
  position: absolute;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1.5rem;
}

.blog-BtnR {
  background-color: #f26828;
  /* font-family: Poppins; */
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding: 0.1rem 2rem;
  border-radius: 0.35rem;
  width: fit-content;
  cursor: pointer;
}

.blog-contentR {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  width: 100%;
  margin: 0;
}

.blog-content1R {
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ababab;
  width: 90%;
  margin: 0;
}

@media (1400px <= width <= 1550px) {
  .blogPageContainer-divL {
    height: 600px;
  }

  .blogPageContainer-divR {
    height: 600px;
  }

  .blogPageContainer2-div {
    height: 288px;
  }
}

@media (1200px <= width <= 1350px) {
  .blogPageContainer-divL {
    height: 500px;
  }

  .blogPageContainer-divR {
    height: 500px;
  }

  .blogPageContainer2-div {
    height: 240px;
  }
}

@media screen and (max-width: 430px) {
  .blogPageContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .blogPageContainer-divL {
    width: calc(100%);
    position: relative;
    height: 300px;
  }

  .blog-img {
    width: 100%;
    border-radius: 1rem;
    height: 100%;
  }

  .blog-Btn {
    background-color: #f26828;
    /* font-family: Poppins; */
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    padding: 0.2rem 2.5rem;
    border-radius: 0.35rem;
    width: fit-content;
    cursor: pointer;
  }

  .blog-content {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    width: 100%;
    margin: 0;
  }

  .blog-content1 {
    color: #686868;
  }

  .blogPageContainer-divL-content {
    position: absolute;
    bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
  }

  .blogPageContainer-divR {
    width: calc(100%);
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    row-gap: 0rem;
  }

  .blogPageContainer-divR-divs {
    width: calc(100%);
    position: relative;
  }

  .blogPageContainer-divR-content {
    position: absolute;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
  }

  .blog-BtnR {
    background-color: #f26828;
    /* font-family: Poppins; */
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    padding: 0.1rem 2rem;
    border-radius: 0.35rem;
    width: fit-content;
    cursor: pointer;
  }

  .blog-contentR {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3rem;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    width: 100%;
    margin: 0;
  }

  .blog-content1R {
    font-size: 0.6rem;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #ababab;
    width: 90%;
    margin: 0;
  }
}

.blogMobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 16px 0px 8px 0px;
  height: 136px;
}

.blogMobile1 {
  width: calc(65%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: space-between;
}

.mblog-img {
  width: calc(30%);
  height: 60%;
}

.mBtnBlog {
  border: 0.6px solid #f26828;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  color: #f26828;
}
