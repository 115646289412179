.adEnquiryContainer {
  display: flex;
  justify-content: space-between;
  padding: 32px 72px;
}

.adEnquiryContainer-btn1 {
  border: 0.86px solid #f26828;
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  color: #f26828;
  width: 210px;
  height: 50px;
  border-radius: 0.5rem;
}

.adEnquiryContainer-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 72px;
}

.adEnquiryContainer-box1 {
  width: 70%;
  box-sizing: border-box;
  padding: 16px;
}

.adEnquiryContainer-box1Head {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 500;
  line-height: 66px;
  text-align: left;
  color: #000000;
}

.adEnquiryContainer-box1Text {
  font-family: Poppins;
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #000000;
}

.adEnquiryContainer-boxDiv {
  display: flex;
  justify-content: space-between;
}

.adEnquiryContainer-boxDivHead {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  color: #000000;
}

.adEnquiryContainer-boxDivInput {
  border: 1.38px solid #a4a4a4;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  color: #000000;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  padding: 0px 16px;
  border-radius: 8px;
  outline: none;
}

.adEnquiryContainer-boxDivInput::placeholder {
  color: #a4a4a4;
}

.adEnquiryContainer-boxDivInput1Div {
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  position: relative;
}

.adEnquiryContainer-boxDivInput1A {
  border: 1.38px solid #a4a4a4;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  color: #a4a4a4;
  width: 20%;
  height: 100%;
  padding: 0px 8px;
  border-radius: 8px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.adEnquiryContainer-boxDivInput1B {
  border: 1.38px solid #a4a4a4;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  color: #000000;
  width: 100%;
  height: 100%;
  padding: 0px 8px 0px 25%;
  border-radius: 8px;
  outline: none;
}

.adEnquiryContainer-boxDivInput1B::placeholder {
  color: #a4a4a4;
}

.adEnquiryContainer-box2 {
  width: 30%;
  box-sizing: border-box;
  padding: 16px;
}

.adEnquiryContainer-box2Main {
  width: 100%;
  padding: 32px 0px;
}

.adEnquiryContainer-box2Align {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.adEnquiryContainer-otpBox {
  border: 0.45px solid #215071;
  height: 67px;
  width: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #215071;
}

.adEnquiryContainer-box3Main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #4d4d4d;
}

.adEnquiryContainer-btnMain {
  box-shadow: 0px 2.91px 29.07px 0px #00000026;
  border: 0.86px solid #f26828;
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  background-color: #f26828;
  color: #fff;
  width: 100%;
  height: 72px;
  border-radius: 0.5rem;
}

@media (700px <= width <= 1000px) {
  .adEnquiryContainer-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 32px;
  }

  .adEnquiryContainer-box1Head {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 66px;
    text-align: left;
    color: #000000;
  }

  .adEnquiryContainer-box1Text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #000000;
  }

  .adEnquiryContainer-boxDivHead {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 33px;
    text-align: left;
    color: #000000;
  }

  .adEnquiryContainer-boxDivInput {
    border: 1.38px solid #a4a4a4;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #000000;
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    padding: 0px 12px;
    border-radius: 8px;
    outline: none;
  }

  .adEnquiryContainer-boxDivInput::placeholder {
    color: #a4a4a4;
  }

  .adEnquiryContainer-boxDivInput1Div {
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .adEnquiryContainer-boxDivInput1A {
    border: 1.38px solid #a4a4a4;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 33px;
    text-align: left;
    color: #a4a4a4;
    width: 30%;
    height: 100%;
    padding: 0px 8px;
    border-radius: 8px;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .adEnquiryContainer-boxDivInput1B {
    border: 1.38px solid #a4a4a4;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 33px;
    text-align: left;
    color: #000000;
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 35%;
    border-radius: 8px;
    outline: none;
  }

  .adEnquiryContainer-boxDivInput1B::placeholder {
    color: #a4a4a4;
  }

  .adEnquiryContainer-box2 {
    width: 30%;
    box-sizing: border-box;
    padding: 16px;
  }

  .adEnquiryContainer-box2Main {
    width: 100%;
    padding: 32px 0px;
  }

  .adEnquiryContainer-box2Align {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  .adEnquiryContainer-otpBox {
    border: 0.45px solid #215071;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #215071;
  }

  .adEnquiryContainer-box3Main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #4d4d4d;
  }

  .adEnquiryContainer-btnMain {
    box-shadow: 0px 2.91px 29.07px 0px #00000026;
    border: 0.86px solid #f26828;
    /* font-family: Poppins; */
    font-size: 24px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    background-color: #f26828;
    color: #fff;
    width: 100%;
    height: 72px;
    border-radius: 0.5rem;
  }
}

/* Laptop below 1500px CSS */
@media screen and (max-width: 1500px) {
  .adEnquiryContainer-btnMain {
    height: 72px;
  }
}

/* Mobile CSS */
@media screen and (max-width: 430px) {
  .adEnquiryContainer {
    display: flex;
    justify-content: center;
    padding: 16px 16px 0px 16px;
  }

  .adEnquiryContainer-btn1 {
    border: 0.86px solid #f26828;
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: #f26828;
    width: 210px;
    height: 50px;
    border-radius: 0.5rem;
  }

  .adEnquiryContainer-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 0px;
  }

  .adEnquiryContainer-box1 {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
  }

  .adEnquiryContainer-box1Head {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    color: #000000;
  }

  .adEnquiryContainer-box1Text {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }

  .adEnquiryContainerM-box {
    width: 85%;
    height: 82%;
    background-color: #ffffffcc;
    position: absolute;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .adEnquiryContainerM-boxA {
    width: 85%;
    height: 64%;
    background-color: #ffffffcc;
    position: absolute;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .adEnquiryContainer-boxDivHead {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #000000;
  }

  .adEnquiryContainer-boxDivInput {
    border: 0.75px solid #a4a4a4;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #000000;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0px 16px;
    border-radius: 8px;
    outline: none;
  }

  .adEnquiryContainer-boxDivInput::placeholder {
    color: #a4a4a4;
  }

  .adEnquiryContainer-boxDivInput1Div {
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .adEnquiryContainer-boxDivInput1A {
    border: 0.75px solid #a4a4a4;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #a4a4a4;
    width: 20%;
    height: 100%;
    padding: 0px 8px;
    border-radius: 8px;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .adEnquiryContainer-boxDivInput1B {
    border: 0.75px solid #a4a4a4;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #000000;
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 25%;
    border-radius: 8px;
    outline: none;
  }

  .adEnquiryContainer-boxDivInput1B::placeholder {
    color: #a4a4a4;
  }

  .adEnquiryContainer-btnMain {
    box-shadow: 0px 2.91px 29.07px 0px #00000026;
    border: 0.86px solid #f26828;
    /* font-family: Poppins; */
    font-size: 11px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    background-color: #f26828;
    color: #fff;
    width: 100%;
    height: 40px;
    border-radius: 0.5rem;
  }

  .adEnquiryContainer-box2Align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .adEnquiryContainer-otpBox {
    border: 0.45px solid #215071;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #215071;
  }
}

.btnAdd {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 2;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 32px;
}

.btnAdd1 {
  position: fixed;
  bottom: 0px;
  right: calc(4%);
  z-index: 2;
  width: 280px;
  box-sizing: border-box;
  padding: 16px 32px;
}
