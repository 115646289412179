.parental-list {
    list-style-type: none;
    padding-left: 0px;
  }
  .parentalcontent {
    margin: 30px 0px 50px 0px;
  }
  
  .sign-img {
    height: 13px;
    margin-right: 5px;
  }
  
  .parental-list li {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .parental-heading {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .req-call-div {
    width: 75%;
    height: 250px;
    background: #ffffff;
    border: 3.13208px solid #000000;
    box-shadow: 0px 12.5283px 12.5283px rgb(0 0 0 / 25%),
      0px 12.5283px 12.5283px rgb(0 0 0 / 25%),
      0px 12.5283px 12.5283px rgb(0 0 0 / 25%);
    border-radius: 59.5094px;
  }
  .parent-img {
    width: 390px;
  }
  .paren-row {
    align-items: center;
  }
  
  .parent-img img {
    display: block;
    margin: auto;
  }
  .call-input {
    width: 75%;
    display: block;
    margin: 12px auto;
    padding-left: 15px;
    height: 35px;
    border-radius: 5px;
    outline: none;
    border: 2px solid #000;
    color: #000;
  }
  .callbackbtn {
    width: 75%;
    margin: auto;
    display: block;
    border-radius: 5px;
    background: #3f6b68;
    color: #fff;
    height: 40px;
    border: 1px solid #3f6b68;
  }
  .pagesupport {
    background: #ebe4c8;
    border: 4px solid #ddd5b7;
    padding: 30px 0px;
    margin-bottom: 30px;
  }
  .left-spport img {
    width: 85%;
  }
  .right-spport p {
    line-height: 30px;
    text-align: justify;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  
  .elderphysio {
    font-size: 20px;
    font-weight: 700;
    color: #000;
  }
  .call-back-heading {
    text-align: center;
    margin: 10px 0px 0px 0px;
    font-size: 18px;
    font-weight: 700;
  }
  .right-parental {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .imgresoiniveslider-partner {
    width: 70%;
    display: block;
    margin: 20px auto 35px auto;
  }
 
  
 

  .right-detail p {
    font-size: 11px;
    color: #000;
    margin-bottom: 0px !important;
  }

  
  .sliderheading {
    margin-top: 30px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 35px;
  }
  
  .imgresoiniveslider-news {
    display: block;
    margin: 20px auto 40px auto;
    width: 70%;
    height: 235px;
  }
  .enroll-h-pragnancy {
    background: #3f6b68;
    border: 3.48px solid #25514e;
    padding: 12px 0px;
  }
  
  .main-plan {
    padding: 20px 0px;
  }
  
  .main-health {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .health-preg-image {
    background: #fff;
    padding: 26px;
    height: 125px;
    border-radius: 50%;
    width: 125px;
  }
  
  .health-preg-image img {
    height: 80px;
    width: 70px;
  }
  
  .health-1 h5 {
    text-align: left;
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
  }
  .healthdes-para {
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 10px;
    color: #fff;
    font-weight: 500;
  }
  
  .replan-img {
    display: block;
    margin: 0px auto 50px auto;
  }
  
  .slidenewsheading {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  /*testimonials heading*/
  .testimonial-main {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 7%);
    border-radius: 12px;
    width: 250px;
    margin: 0px auto 40px auto;
    padding: 15px;
    height: 290px;
  }
  .feed-testi-heading {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
  }
  .feed-testi-para {
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .rating {
    display: flex;
    gap: 5px;
    list-style-type: none;
    padding-left: 0px;
  }
  
  .healthdes-para {
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  
  .client-pic {
    box-shadow: 0px 3px 10px #ccc;
    border-radius: 50%;
    height: 40px;
  }
  
  .cline-name {
    margin-bottom: 0px;
  }
  
  .photo-client-main {
    display: flex;
    gap: 10px;
  }
  
  .sliderheadingtest {
    margin-top: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
  }
  