.operativeCarePageContainer3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 9rem;
}

@media (width <=1300px) {
  .operativeCarePageContainer3 {
    padding: 1rem 8rem;
  }
}
