.parental-list-pregnancy {
    list-style-type: none;
    padding-left: 0px;
  }
  .parentalcontent-pregnancy {
    margin: 30px 0px 50px 0px;
  }
  
  .sign-img-pregnancy {
    height: 13px;
    margin-right: 5px;
  }
  
  .parental-list-pregnancy li {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .parental-heading-pregnancy {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .req-call-div-pregnancy {
    width: 75%;
    height: 250px;
    background: #ffffff;
    border: 3.13208px solid #000000;
    box-shadow: 0px 12.5283px 12.5283px rgb(0 0 0 / 25%),
      0px 12.5283px 12.5283px rgb(0 0 0 / 25%),
      0px 12.5283px 12.5283px rgb(0 0 0 / 25%);
    border-radius: 59.5094px;
  }
  .parent-img-pregnancy {
    width: 390px;
  }
  .paren-row {
    align-items: center;
  }
  
  .parent-img-pregnancy img {
    display: block;
    margin: auto;
  }
  .call-input-pregnancy {
    width: 75%;
    display: block;
    margin: 12px auto;
    padding-left: 15px;
    height: 35px;
    border-radius: 5px;
    outline: none;
    border: 2px solid #000;
    color: #000;
  }
  .callbackbtn-pregnancy {
    width: 75%;
    margin: auto;
    display: block;
    border-radius: 5px;
    background: #3f6b68;
    color: #fff;
    height: 40px;
    border: 1px solid #3f6b68;
  }
  .call-back-heading-pregnancy {
    text-align: center;
    margin: 10px 0px 0px 0px;
    font-weight: 700;
    font-size: 17px;
  }
  .right-parental-pregnancy {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .plan-detail-pregnancy {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
  }
  
  .plan-slider-pregnancy {
    margin: auto;
    width: 95%;
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 1px 0px;
    margin-bottom: 25px;
  }
  
  .selectplainlist-main-pregnancy {
    height: 175px;
    border-bottom: 2px solid #000;
    padding-top: 15px;
  }
  
  .bullet-pregnancy {
    width: 6px;
    height: 6px;
    background-color: #3f6b68;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .selecpra-button-pregnancy {
    background: #3f6b68;
    border-radius: 5px;
    border: none;
    height: 40px;
    color: #fff;
    width: 135px;
    margin: 15px auto 30px auto;
    display: block;
  }
  
  .left-detail-pregnancy p {
    font-size: 11px;
    margin-bottom: 0px;
    color: #000;
  }
  
  .right-detail-pregnancy p {
    font-size: 11px;
    color: #000;
    margin-bottom: 0px !important;
  }
  
  .plan-heading-pregnancy h4 {
    padding: 15px;
    text-align: center;
    background: #ebe4c8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  
  .sliderheading-pregnancy {
    margin-top: 20px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .imgresoiniveslider-news-pregnancy {
    display: block;
    margin: 20px auto 30px auto;
    width: 65%;
    height: 235px;
  }
  
  .photo-client-main-pregnancy {
    display: flex;
    gap: 10px;
  }
  
  .enroll-h-pragnancy-pregnancy {
    background-color: #ebe4c8;
    padding: 12px 0px;
  }
  
  .main-plan-pregnancy {
    padding: 20px 0px;
  }
  
  .main-health-pregnancy {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .health-preg-image-pregnancy {
    background: #fff;
    padding: 26px;
    height: 125px;
    border-radius: 50%;
    width: 125px;
  }
  
  .health-preg-image-pregnancy img {
    height: 80px;
    width: 70px;
  }
  
  .health-1-pregnancy h5 {
    text-align: left;
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }
  .healthdes-para-pregnancy {
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 500;
  }
  
  .imgresoiniveslider-news-pregnancy {
    display: block;
    margin: 20px auto 30px auto;
    width: 65%;
    height: 235px;
  }
  
  .imgresoiniveslider-partner-pregnancy {
    display: block;
    margin: 20px auto 30px auto;
    width: 70%;
  }
  
  .galleryimgresoiniveslider-pregnancy {
    display: block;
    margin: 10px auto 30px auto;
    width: 65%;
    height: 175px;
  }

  