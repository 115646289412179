.cgT {
  display: flex;
  justify-content: center;
  padding: 32px 72px;
  flex-direction: column;
  align-items: center;
  background-color: #FFFBF4;
}

.cgTHead {
  /* font-family: Poppins; */
  font-size: 52px;
  font-weight: 600;
  line-height: 78px;
  text-align: center;
  color: #000000;
  padding: 16px 0px;
  margin: 0;
}

.cgTText {
  /* font-family: Poppins; */
  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.cgTBtn {
  border: 1.18px solid #f26828;
  box-shadow: 0px 4.01px 40.12px 0px #00000026;
  width: 358px;
  height: 72px;
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #fff;
  background-color: #f26828;
  margin-top: 72px;
  border-radius: 8px;
}

@media screen and (max-width: 430px) {
  .cgT {
    display: flex;
    justify-content: center;
    padding: 64px 16px;
    flex-direction: column;
    align-items: center;
  }

  .cgTHead {
    /* font-family: Poppins; */
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
    
    color: #000000;
    padding: 16px 0px;
    margin: 0;
  }

  .cgTText {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    
    color: #000000;
    margin: 0;
  }

  .cgTBtn {
    border: 1.18px solid #f26828;
    box-shadow: 0px 4.01px 40.12px 0px #00000026;
    width: 60%;
    height: 48px;
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    
    color: #fff;
    background-color: #f26828;
    margin-top: 32px;
    border-radius: 8px;
  }
}
