.labTestPageContainer2 {
  padding: 3rem 5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}

.labTestPageContainer2-slider {
  padding: 0rem 12rem;
  position: relative;
}

.labTestPageContainer3-text {
  width: 75%;
  color: #4D4D4D;
}

@media (1200px <= width <=1500px) {
  .labTestPageContainer2-slider {
    padding: 0rem 5rem;
  }
}

.labTestPageContainer2-card {
  width: 100% !important;
  box-sizing: border-box;
}

.labTestPageContainer2-cardHead {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.08523206412792206px;
  text-align: left;
  color: #000000;
  text-transform: capitalize;
}

.labTestPageContainer2-cardText {
  /* font-family: Poppins; */
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.10742316395044327px;
  text-align: left;
  color: #000000;
  height: 5rem;
}

.labTestPageContainer2-cardImg {
  border-right: 0.5rem solid #215071;
  border-radius: 100%;
  display: block;
  margin: auto;
  width: 60% !important;
}

.labTestPageContainer2-cardDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0rem;
}

.labTestPageContainer2-cardBtn1 {
  /* font-family: Poppins; */
  font-size: 12px;
  font-weight: 400;
  line-height: 19.11px;
  letter-spacing: 0.2029717117547989px;
  text-align: center;
  color: #000000;
  border: 0.41px solid #f26828;
  height: 2.5rem;
  width: calc(56%);
  border-radius: 0.5rem;
  color: #f26828;
}

.labTestPageContainer2-cardBtn2 {
  /* font-family: Poppins; */
  font-size: 12px;
  font-weight: 500;
  line-height: 19.11px;
  text-align: center;
  color: #ffffff;
  background-color: #f26828;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: calc(42%);
}

@media only screen and (max-width: 430px) {
  .labTestPageContainer2 {
    padding: 3rem 1rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: center;
  }

  .labTestPageContainer2-card {
    width: calc(100%);
    height: auto;
    border-radius: 0.2rem;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }

  .labTestPageContainer2-cardHead {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.08523206412792206px;
    text-align: left;
    color: #000000;
  }

  .labTestPageContainer2-cardText {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.10742316395044327px;
    text-align: left;
    color: #000000;
    padding: 0rem 0rem;
  }

  .labTestPageContainer2-cardImg {
    border-right: 0.5rem solid #215071;
    border-radius: 100%;
    display: block;
    margin: auto;
    width: 100%;
  }

  .labTestPageContainer2-cardDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 0rem;
  }

  .labTestPageContainer2-cardBtn1 {
    /* font-family: Poppins; */
    font-size: 9.74px;
    font-weight: 400;
    line-height: 19.11px;
    letter-spacing: 0.2029717117547989px;
    text-align: center;
    color: #f26828;
    border: 0.41px solid #f26828;
    height: 1.5rem;
    width: calc(52%);
    border-radius: 0.3rem;
  }

  .labTestPageContainer2-cardBtn2 {
    /* font-family: Poppins; */
    font-size: 9.74px;
    font-weight: 500;
    line-height: 19.11px;
    text-align: center;
    color: #ffffff;
    background-color: #f26828;
    border-radius: 0.3rem;
    height: 1.5rem;
    width: calc(44%);
  }
}

.lab-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  padding: 1rem 0rem 2rem 0rem;
}

.lab-image {
  width: auto;
  height: auto;
  object-fit: contain;
}

@media (1400px <= width <= 1500px) {
  .lab-image-container {
    gap: 0rem;
  }
  .lab-image {
    width: 350px;
    height: 120px;
  }
}

@media (1200px <= width <= 1300px) {
  .lab-image-container {
    gap: 0rem;
  }
  .lab-image {
    width: 300px;
    height: 90px;
  }
}

@media (width <= 430px) {
  .lab-image-container {
    gap: 2rem;
    padding: 1rem 0rem 2rem 0rem;
  }
  .lab-image {
    width: 136px;
    height: 56px;
  }
}
