.request-call-lab{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .sub-req-contetn-lab{
    background-color: rgba(0,0,0,.2);
    box-shadow: 0px 8px 10px 10px rgb(130 130 130 / 40%);
    padding: 20px 0px !important;
    height: 100%;
  }
  .close-bnt-lab{
    margin-left: auto;
    display: block;
    background-color: transparent;
    outline: none;
    color: #000;
    margin-bottom: 10px;
    border: none;
    font-weight: 700;
    font-size: 25px;
  }
  .req-call-content-lab{
    display: block;
    margin: auto;
    width: 575px;
    background-color: #fff;
    padding:15px 25px;
    margin-top: 50px;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%);
  }
  
  .lets-talk-lab{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
  }
  
  .popup-heading-lab{
    font-size: 24px;
      text-align: center;
      font-weight: 700;
      margin-top: -20px;
      color: #3f6b68;
  }
  .popup-para-lab{
    color: #000;
      font-size: 12px;
      font-weight: 500;
  }
  .popup-list-lab li{
    color: #000;
      font-size: 12px;
      font-weight: 500;
  }
  .popupbanner-lab{
    height: 55px;
    display: block;
    margin: 0px auto 30px auto;
  }