.mfbContainer {
  position: fixed;
  top: 0rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 999;
}

.mfbContainer1 {
  position: fixed;
  bottom: calc(11.3%);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem 1rem 0rem 2rem;
  z-index: 999;
}

.mfbContainer2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.mfbContainer-div {
  width: calc(80%);
  background-color: #fff;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin-bottom: 8px;
}

.mfbContainerImg {
  position: fixed;
  bottom: calc(2%);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1.3rem;
}
