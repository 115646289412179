.footerContainer {
  padding: 48px 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background-color: #f5fbff;
}

.footerContainerDiv1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 12rem;
}

@media (1400px <= width <= 1500px) {
  .footerContainerDiv1 {
    padding: 0rem 6rem;
  }
}

@media (1200px <= width <= 1300px) {
  .footerContainerDiv1 {
    padding: 0rem 4rem;
  }
}

.footerContainerDiv1-h {
  padding: 0px 0px 4px 0px;
  font-weight: 600;
}

.footerContainerDiv1-p {
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  padding: 0px 0px 8px 0px;
  cursor: pointer;
  opacity: 1;
}

.footerContainerDiv1-p:hover {
  opacity: 0.8;
}

.footerContainerDiv2 {
  width: calc(40%);
}

.footerContainerDiv2-imgDiv {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.footerImgs {
  width: calc(30%);
  height: 2rem;
}

.footerContainerDiv2-rightDivMain {
  width: 100%;
  display: flex;
  /* height: 300px; */
  justify-content: space-between;
}

.footerContainerDiv2-rightDiv {
  width: 100%;
  display: flex;
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
}

.footerContainerDiv2-div1 {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0rem;
}

.footerContainerIcons {
  padding: 4px;
  background-color: #000;
  border-radius: 100%;
  font-size: 0.5rem;
  cursor: pointer;
  opacity: 1;
}

.footerContainerIcons:hover {
  opacity: 0.8;
}

.footerContainerDiv2-div2 {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 2.5rem 0rem 1.5rem;
}

.footerMap {
  border-radius: 2rem;
  background-color: #fafafa;
  /* width: 90%; */
  height: 170px;
}

.footerAddress {
  display: flex;
  flex-direction: column;
}

.footerAddress-p {
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  padding: 0px 0px 2px 0px;
  cursor: pointer;
  opacity: 1;
}

.footerAddress-p:hover {
  opacity: 0.8;
}

.footerContainerLst {
  padding: 0px 48px 16px 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5fbff;
  gap: 3rem;
  position: relative;
}
.footerContainerLst .footerAddress-p {
  font-size: 1rem;
  font-weight: 400;
}

.footerBox {
  background-color: #000000;
  width: calc(65%);
  height: auto;
  position: fixed;
  left: calc(30%);
  bottom: calc(8%);
  border-radius: 16px;
  padding: 64px;
  height: 336px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerBox-h {
  font-size: 24px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  color: #ff915e;
}

.footerBox-t {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
}

.footerBox-alignbtn {
  padding: 16px 0px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.footerBox-btn11 {
  height: 48px;
  color: #ffffff;
  background-color: #f26828;

  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  color: #fff;
  width: 140px;
  border-radius: 8px;
}

.footerBox-input {
  height: 48px;
  background-color: transparent;
  outline: none;
  border: 1.07px solid #ffffff;
  border-radius: 8px;
  padding: 0px 16px;
  width: 300px;
  color: #fff;
}

.footerBox-inputError {
  color: red;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  bottom: calc(10%);
}

.footer-icons {
  position: absolute;
  top: 60%;
  left: 12%;
  display: flex;
  flex-direction: column;
}

@media (1400px <= width <= 1500px) {
  .footer-icons {
    left: 10%;
  }
}

@media (1200px <= width <= 1300px) {
  .footer-icons {
    left: 9%;
  }
}

@media screen and (max-width: 430px) {
  .footerBox {
    background-color: #000000;
    width: calc(84%);
    height: auto;
    position: fixed;
    left: calc(13%);
    bottom: calc(5%);
    border-radius: 8px;
    padding: 16px;
  }

  .footerBox-h {
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    color: #ff915e;
  }

  .footerBox-t {
    font-size: 6.72px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #fff;
    padding: 4px 0px;
  }

  .footerBox-alignbtn {
    padding: 8px 0px;
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .footerBox-btn11 {
    height: 40px;
    color: #ffffff;
    background-color: #f26828;

    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    color: #fff;
    width: 100px;
    border-radius: 4px;
  }

  .footerBox-input {
    height: 40px;
    background-color: transparent;
    outline: none;
    border: 1.07px solid #ffffff;
    border-radius: 4px;
    padding: 0px 8px;
    width: 160px;
    font-size: 10px;
    font-weight: 400;
    line-height: 11.2px;
    text-align: left;
    color: #fff;
  }

  .footerBox-inputError {
    color: red;
    font-size: 10px;
    font-weight: 400;
    line-height: 25px;
    position: absolute;
    bottom: calc(-10%);
  }
}
