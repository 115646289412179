.generalWellnessPageContainer3 {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 0rem 1rem 5rem;
}

@media screen and (max-width: 430px) {
  .generalWellnessPageContainer3 {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem 0rem 1rem 2rem;
  }
}
