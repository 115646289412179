.caregive-ad-img {
  width: 70%;
  display: block;
  margin: 120px auto;
}

.caregive-ad-align1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 72px;
  gap: 16px;
}

.caregive-ad-box1 {
  border: 0.51px solid #000000;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: calc(15%);
  box-sizing: border-box;
  height: 340px;
  padding: 16px;
  border-radius: 8px;
}

.caregive-ad-box1-image {
  width: 90%;
  height: 200px;
}

.caregive-ad-box1-text {
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
  color: #000000;
  text-transform: capitalize;
}

.caregive-ad-footer {
  padding: 32px 72px;
  background-color: #215071;
  margin-top: 32px;
}
.caregive-ad-box1-text1 {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  color: #ffffff;
  margin: 32px 0px 12px 0px;
}

.caregive-ad-box1-text2 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #fff;
  padding: 0px 0px 0px 0px;
}

.caregive-ad-box1-text3 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #fff;
  padding: 32px 0px 64px 0px;
}

.caregive-ad-heads {
  font-size: 26px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0;
}

.caregive-ad-heads1 {
  font-size: 26px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  padding: 1rem 0rem;
  margin: 0;
}

.caregive-ad-box2 {
  width: 100%;
  position: relative;
  margin: 32px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caregive-ad-box21 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 32px;
  margin: auto;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 32px 122px;
}

.caregive-ad-box2-card {
  position: relative;
  width: 100%;
}

.caregive-ad-box2-card1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 24px 0px 0px 0px;
}

.caregive-ad-box2-head {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  color: #fff;
}

.caregive-ad-box2-text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #fff;
}

@media (1550px<= width <= 1700px) {
  .caregive-ad-box21 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 32px;
    margin: auto;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 122px;
  }

  .caregive-ad-box2-card {
    position: relative;
    width: 100%;
  }

  .caregive-ad-box2-card1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 16px 0px 0px 0px;
  }

  .caregive-ad-box2-head {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 39px;
    text-align: left;
    color: #fff;
  }

  .caregive-ad-box2-text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #fff;
  }
}

@media (1300px <= width <= 1440px) {
  .caregive-ad-box21 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 32px;
    margin: auto;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 122px;
  }

  .caregive-ad-box2-card {
    position: relative;
    width: 100%;
  }

  .caregive-ad-box2-card1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 16px 0px 0px 0px;
  }

  .caregive-ad-box2-head {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 39px;
    text-align: left;
    color: #fff;
  }

  .caregive-ad-box2-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }
}

@media (1000px <= width <= 1280px) {
  .caregive-ad-box21 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 32px;
    margin: auto;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 122px;
  }

  .caregive-ad-box2-card {
    position: relative;
    width: 100%;
  }

  .caregive-ad-box2-card1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    padding: 8px 0px 0px 0px;
  }

  .caregive-ad-box2-head {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 39px;
    text-align: left;
    color: #fff;
  }

  .caregive-ad-box2-text {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }
}

@media (1000px <= width <=1199px) {
  .caregive-ad-heads {
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0rem 0rem 2rem 0rem;
    margin: 0;
  }

  .caregive-ad-heads1 {
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 2rem 0rem 1rem 0rem;
    margin: 0;
  }

  .caregive-ad-img {
    width: 70%;
    display: block;
    margin: 120px auto;
  }

  .caregive-ad-align1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 72px;
    gap: 16px;
  }

  .caregive-ad-box1 {
    border: 0.51px solid #000000;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: calc(15%);
    box-sizing: border-box;
    height: 240px;
    padding: 16px;
    border-radius: 8px;
  }

  .caregive-ad-box1-image {
    width: 90%;
    height: 140px;
  }

  .caregive-ad-box1-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #000000;
    text-transform: capitalize;
  }

  .caregive-ad-footer {
    padding: 32px 72px;
    background-color: #215071;
    margin-top: 32px;
  }
  .caregive-ad-box1-text1 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
  }

  .caregive-ad-box1-text2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #fff;
    padding: 0px 0px 0px 0px;
  }

  .caregive-ad-box1-text3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #fff;
    padding: 0px 0px 32px 0px;
  }
}

@media (600px <= width <=900px) {
  .caregive-ad-heads {
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0rem 0rem 2rem 0rem;
    margin: 0;
  }

  .caregive-ad-heads1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 2rem 0rem 1rem 0rem;
    margin: 0;
  }

  .caregive-ad-img {
    width: 70%;
    display: block;
    margin: 120px auto;
  }

  .caregive-ad-align1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    gap: 16px;
  }

  .caregive-ad-box1 {
    border: 0.51px solid #000000;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: calc(15%);
    box-sizing: border-box;
    height: 240px;
    padding: 16px;
    border-radius: 8px;
  }

  .caregive-ad-box1-image {
    width: 90%;
    height: 140px;
  }

  .caregive-ad-box1-text {
    font-size: 9px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    color: #000000;
    text-transform: capitalize;
  }

  .newpadding {
    padding: 0px 72px;
  }

  .newDisplay {
    display: none;
  }

  .caregive-ad-footer {
    padding: 32px 72px;
    background-color: #215071;
    margin-top: 32px;
  }
  .caregive-ad-box1-text1 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
  }

  .caregive-ad-box1-text2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #fff;
    padding: 0px 0px 0px 0px;
  }

  .caregive-ad-box1-text3 {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #fff;
    padding: 0px 0px 32px 0px;
  }
}

@media (width<=430px) {
  .caregive-ad-heads {
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0rem 0rem 0rem 0rem;
    margin: 0;
  }

  .caregive-ad-heads1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding: 0rem;
    margin: 0;
  }

  .caregive-ad-img {
    width: 80%;
    display: block;
    margin: 32px auto;
  }

  .caregive-ad-align1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 32px 16px;
    gap: 16px;
  }

  .caregive-ad-box1 {
    border: 0.51px solid #000000;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: calc(30%);
    box-sizing: border-box;
    height: 160px;
    padding: 16px;
    border-radius: 8px;
  }

  .caregive-ad-box1-image {
    width: 95%;
    height: 84px;
  }

  .caregive-ad-box1-text {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #000000;
    text-transform: capitalize;
  }

  .caregive-ad-footer {
    padding: 32px;
    background-color: #215071;
    margin-top: 32px;
  }
  .caregive-ad-box1-text1 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    color: #ffffff;
  }

  .caregive-ad-box1-text2 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    padding: 0px 0px 32px 0px;
  }
  .newDisplay {
    display: none;
  }

  .caregive-ad-box1-text1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #ffffff;
  }

  .caregive-ad-box1-text2 {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #fff;
    padding: 0px 0px 0px 0px;
  }

  .caregive-ad-box1-text3 {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #fff;
    padding: 0px 0px 32px 0px;
  }
}

@media (width <= 430px) {
  .caregive-ad-box2 {
    width: 100%;
    position: relative;
    margin: 32px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .caregive-ad-box21 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
    gap: 16px;
    margin: auto;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 32px;
  }

  .caregive-ad-box2-card {
    position: relative;
    width: 100%;
    height: 200px;
  }

  .caregive-ad-box2-card1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    padding: 8px 0px 0px 0px;
  }

  .caregive-ad-box2-head {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }

  .caregive-ad-box2-text {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    color: #fff;
    margin: 0;
  }
}
