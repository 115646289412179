.main-heading-stroke {
    font-size: 30px;
    text-align: center;
    color: #3f6b68;
    font-weight: 900;
    margin: 30px 0px;
  }
  
  .safety-para-stroke {
    font-size: 15px;
    color: #000;
    font-weight: 600;
  }
  
  .spacing-stroke {
    margin: 30px 0px;
  }
  
  .engage-main-stroke {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 0px;
  }
  
  .engage-main-stroke h6 {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    width: 100%;
    font-weight: 600;
  }
  
  .engageimg-div-stroke {
    background: #ebe4c8;
    padding: 26px;
    height: 125px;
    border-radius: 50%;
    width: 125px;
    margin-bottom: 30px;
    border: 2px solid #ddd5b7;
  }
  
  .aidkit-1-stroke{
    margin: 50px 0;
  }
  .aidkit-2-stroke{
    margin: 10px 0 50px 0px;
  }
  
  .engageimg-div-stroke img {
    height: 75px;
    margin: auto;
    display: block;
  }
  
  .roadsafety-list-stroke li {
    font-size: 15px;
    font-weight: 600;
    line-height: 36px;
  }
  
  .booknewawssion-stroke {
    background: #ebe4c8;
    border: 4px solid #ddd5b7;
  }
  .sessioncall-btn-stroke {
    display: block;
    margin: auto;
    height: 50px;
    width: 175px;
    border-radius: 10px;
    background-color: #3f6b68;
    border: 2px solid #3f6b68;
    color: #fff;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .sessioncall-para-stroke {
    color: #888888;
    text-align: center;
    font-size: 18px;
    margin: 20px 0px;
    font-weight: 600;
  }
  
  .sessioncall-heading-stroke {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 10px 0px 30px 0px;
  }
  
  /**life support section css***/
  .pagesupport-stroke{
    background: #EBE4C8;
    border: 4px solid #DDD5B7;
    padding: 30px 0px;
    margin-bottom: 30px;
  }
  .left-spport-stroke img{
    width: 55%;
    display: block;
    margin: auto;
  }
  .right-spport-stroke p{
    line-height: 30px;
    text-align: justify;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  /**life support section css end**/
  
  .roadmap-fristkit-stroke{
    width: 80%;
    margin: 50px auto;
    display: block;
  }
  
  .electrical-photo-stroke{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .elctriccontent-stroke{
    width: 18%;
  }
  
  .stroke6image-stroke{
    display: block;
      margin: 20px auto;
      width: 30%;
  }
  
  .capital-big-stroke{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .bigcap-stroke h2{
    font-size: 60px;
    font-weight: 900;
    color: #86A4A2;
    margin-right: 20px;
    margin-bottom: 0px;
    width: 42px;
    display: flex;
    justify-content: center;
  }
  
  .bigcap-para-stroke p{
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0px;
  }
  
  
  /*popup css start*/
  .request-call{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .sub-req-contetn{
    background-color: rgba(0,0,0,.2);
    box-shadow: 0px 8px 10px 10px rgb(130 130 130 / 40%);
    padding: 20px 0px !important;
    height: 100%;
  }
  .close-bnt{
    margin-left: auto;
    display: block;
    background-color: transparent;
    outline: none;
    color: #000;
    margin-bottom: 10px;
    border: none;
    font-weight: 700;
    font-size: 25px;
  }
  .req-call-content{
    display: block;
    margin: auto;
    width: 320px;
    background-color: #fff;
    padding: 25px;
    margin-top: 80px;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%);
  }
  
  .reqcall-input{
    width: 270px;
    margin: 0px auto 10px auto;
    display: block;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 10px;
    color: #000;
    height: 40px;
  }
  
  .lets-talk{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .lets-talk h6{
    font-size: 18px;
    font-weight: 700;
  }
  
  .reqcall-btn{
    width: 270px;
    margin: 0px auto 10px auto;
    display: block;
    border-radius: 5px;
    border: 1px solid #3F6B68;
    background-color: #3F6B68;
    padding-left: 10px;
    color: #fff;
    height: 40px;
  }
  /*pop up css end*/