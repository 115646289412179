.consultationPageContainer1Content {
  position: absolute;
  top: 0px;
  height: 100%;
  padding: 0rem 0rem 4rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 0;
  width: 100%;
}

.consultationPageImg1 {
  width: 100%;
}

.consultationPageContainer1Content-Head {
  margin: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 48px;
}

.consultationPageContainer1Content-text {
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  letter-spacing: 0.2px;
  padding: 16px 0px 32px;
  font-weight: 500;
}

.consultationPageContainer1Content-btn {
  background-color: #f26828;
  color: #ffffff;
  width: 15rem;
  height: 4rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  opacity: 1;
  font-weight: 600;
  cursor: pointer;
}

.consultationPageContainer1Content-btn:hover {
  opacity: 0.8;
}

.consultationPageContainer2 {
  padding: 1rem 8rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  box-sizing: border-box;
}

.consultationPageContainer3 {
  padding: 3rem 8rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  justify-content: space-between;
  align-items: flex-start;
}

.consultationPageContainer3-card {
  width: calc(30%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.consultationPageContainer3-TextDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  width: 60%;
  top: calc(45%);
}

.consultationPageContainer3-card-img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
}

.consultationPageContainer3-Head {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  color: #000000;
}

.consultationPageContainer3-Text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #4d4d4d;
}

@media (width <= 1500px) {
  .consultationPageContainer3-TextDiv {
    width: 85%;
    top: calc(40%);
  }

  .consultationPageContainer3-card-img {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 430px) {
  .consultationPageContainer1Content {
    bottom: 0px;
    padding: 0rem 0rem 2rem 0rem;
  }

  .consultationPageContainer1Content-Head {
    font-size: 22px;
  }

  .consultationPageContainer1Content-text {
    font-size: 12px;
    padding: 8px 24px 10px 24px;
  }

  .consultationPageContainer1Content-btn {
    width: 8rem;
    height: 2rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    opacity: 1;
    font-weight: 500;
  }

  .consultationPageContainer1Content-btn:hover {
    opacity: 1;
  }

  .consultationPageContainer3 {
    padding: 1rem;
    row-gap: 2rem;
  }

  .consultationPageContainer3-card {
    width: calc(47%);
  }

  .consultationPageContainer3-TextDiv {
    top: calc(30%);
  }

  .consultationPageContainer3-card-img {
    width: 40px;
    height: 40px;
  }

  .consultationPageContainer3-Head {
    font-size: 14px;
    line-height: 20px;
  }

  .consultationPageContainer3-Text {
    font-size: 10px;
    line-height: 16px;
  }
}
