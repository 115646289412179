.headerDrawerCOntainer {
  width: 100%;
  box-sizing: border-box;
  /* padding: 1rem; */
  height: 100%;
  position: relative;
  z-index: 1;
}

.headerDrawerCOntainer-Div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
}
.headerDrawerCOntainer-Img{
  height: 32px;
  width: 36px;
}

.headerDrawerCOntainer-Div1 {
  padding: 2rem 0rem;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
}

.headerDrawerCOntainer-head1 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin: 8px 0;
  cursor: pointer;
}

.headerDrawerCOntainer-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin: 8px 0;
  cursor: pointer;
}


.drawerIcons {
  padding: 4px;
  background-color: #ffffff;
  border-radius: 100%;
  font-size: 0.5rem;
  cursor: pointer;
  opacity: 1;
}

.headerDrawerCOntainer-Div2 {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 2rem 0rem;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}
