.elderlyCarePageContainer1 {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.elderlyCarePageImg1 {
  width: 100%;
}

.elderlyCarePageContainer1Content {
  position: absolute;
  top: 0px;
  height: 100%;
  padding: 0rem 0rem 4rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 0;
  width: 100%;
  background-image: linear-gradient(
    348.7deg,
    #000000 22.56%,
    rgba(0, 0, 0, 0) 75.96%
  );
}

.elderlyCarePageContainer1Content-Head {
  color: #ffffff;
  font-weight: 700;
}

.elderlyCarePageContainer1Content-text {
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.2px;
  padding: 16px 0px;
}

.elderlyCarePageContainer1Content-btn {
  background-color: #f26828;
  color: #ffffff;
  width: 9rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  opacity: 1;
  cursor: pointer;
}

.elderlyCarePageContainer1Content-btn:hover {
  opacity: 0.8;
}

.elderlyCarePageContainer2 {
  position: relative;
}

.elderlyCarePageContainer2-div {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  top: 0;
  box-sizing: border-box;
}

.elderlyCarePageContainer2-div1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.elderlyCarePageContainer2-div2 {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: 3rem;
  width: 100%;
}

.elderlyCarePageContainer2-div-h {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.19658511877059937px;
  text-align: left;
  color: #ffffff;
}

.elderlyCarePageContainer2-div-t {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.19658511877059937px;
  text-align: left;
  color: #ffffff;
  width: 100%;
  padding: 1rem 0rem;
}

.elderlyCarePageContainer2-div-t1 {
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.22962571680545807px;
  text-align: left;
  color: #ffffff;
  padding: 0px 0px 8px 0px;
}

.elderlyCarePageContainer2-div-t2 {
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.22962571680545807px;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-transform: capitalize;
}

.elderlyCarePageContainer2-div-t3 {
  /* font-family: Poppins; */
  font-size: 18px;
  font-style: italic;
  font-weight: 275;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0px 0rem 0px 0px;
}

.elderlyCarePageContainer2-aligncheckbox {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.elderlyCarePageContainer2-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0rem;
}

.elderlyCarePageContainer2-btn1 {
  border: 0.6px solid #ffffff;
  /* font-family: Poppins; */
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0rem 3rem;
  height: 47px;
  border-radius: 0.5rem;
}

.elderlyCarePageContainer2-btn2 {
  background-color: #f26828;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0rem 3rem;
  height: 47px;
  border-radius: 0.5rem;
}

.elderlyCarePageContainer2-btn3 {
  border: 0.6px solid #ffffff;
  /* font-family: Poppins; */
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0rem 2rem;
  height: 47px;
  border-radius: 0.5rem;
}

.elderlyCarePageContainer-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem;
}

.elderlyCarePageContainer-toggle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf7ff;
  padding: 0.5rem 0.5rem;
  border-radius: 2rem;
  width: auto;
}

.elderlyCarePageContainer-toggleActive {
  background-color: #215071;
  width: 150px;
  height: 2.5rem;
  /* font-family: Poppins; */
  font-size: 18.97px;
  font-weight: 600;
  line-height: 28.46px;
  text-align: center;
  color: #fff;
  border-radius: 1rem;
  cursor: pointer;
  opacity: 1;
}

.elderlyCarePageContainer-toggleUnActive {
  background-color: transparent;
  width: 150px;
  height: 2.5rem;
  /* font-family: Poppins; */
  font-size: 18.97px;
  font-weight: 600;
  line-height: 28.46px;
  text-align: center;
  color: #215071;
  border-radius: 1rem;
  cursor: pointer;
  opacity: 1;
}

.elderlyCarePageContainer-toggleActive:hover {
  opacity: 0.8;
}

.elderlyCarePageContainer-toggleUnActive:hover {
  opacity: 0.8;
}

.elderlyCarePageContainer3 {
  padding: 2rem 8rem 3rem 8rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 2rem;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  /* background-image: linear-gradient(135deg, #F26828 60%, #CBE9FF 30%); */
}

.elderlyCarePageContainer3-img1 {
  position: absolute;
  z-index: 0;
  left: 0;
}

.elderlyCarePageContainer3-img2 {
  position: absolute;
  z-index: 0;
  right: 0;
}

.elderlyCarePageContainer3-card {
  width: calc(20%);
  box-shadow: 0px 7px 16px 0px #45454505;
  height: 26rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  gap: 1rem;
  background-color: #fff;
  padding-top: 1rem;
}

.elderlyCarePageContainer3-cardImg {
  width: calc(100%);
  display: block;
  margin: auto;
}

.elderlyCarePageContainer3-cardText1 {
  position: absolute;
  left: calc(10%);
  top: calc(10.5%);
  /* font-family: Poppins; */
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.13966697454452515px;
  text-align: center;
  color: #bcbcbc;
}

.elderlyCarePageContainer3-cardHead {
  margin: 0;
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.13966697454452515px;
  text-align: center;
  color: #000000;
  padding: 0px 1rem;
}

.elderlyCarePageContainer3-cardText2 {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.21804547309875488px;
  text-align: center;
  color: #a4a4a4;
  padding: 0rem 2rem;
}

.elderlyCarePageContainer3-cardB1 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(12.5%);
  left: calc(25%);
}

.elderlyCarePageContainer3-cardB2 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(12.5%);
  right: calc(25%);
  rotate: 90deg;
}

.elderlyCarePageContainer3-cardB3 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(38%);
  right: calc(25%);
  rotate: 180deg;
}

.elderlyCarePageContainer3-cardB4 {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #d0d0d0;
  position: absolute;
  top: calc(38%);
  left: calc(25%);
  rotate: 270deg;
}

.elderlyCarePageContainer2imgbg {
  width: auto;
}

.elderlyCarePageContainer-checkBox {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

@media (1200px <= width <= 1500px) {
  .elderlyCarePageContainer2 {
    position: relative;
  }

  .elderlyCarePageContainer2imgbg {
    width: 430px;
  }

  .elderlyCarePageContainer2-div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    top: 0;
    box-sizing: border-box;
  }

  .elderlyCarePageContainer2-div1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .elderlyCarePageContainer2-div2 {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 1.5rem;
    width: 100%;
  }

  .elderlyCarePageContainer2-div-h {
    font-size: 24px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
  }

  .elderlyCarePageContainer-checkBox {
    width: 24px;
    height: 24px;
    border-radius: 100%;
  }

  .elderlyCarePageContainer2-div-t {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
    width: 100%;
    padding: 0rem 0rem;
  }

  .elderlyCarePageContainer2-div-t1 {
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    padding: 0px 0px 8px 0px;
  }

  .elderlyCarePageContainer2-div-t2 {
    /* font-family: Poppins; */
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    margin: 0;
    text-transform: capitalize;
  }

  .elderlyCarePageContainer2-div-t3 {
    /* font-family: Poppins; */
    font-size: 18px;
    font-style: italic;
    font-weight: 275;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0px 0rem 0px 0px;
  }

  .elderlyCarePageContainer2-aligncheckbox {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .elderlyCarePageContainer2-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0rem;
  }

  .elderlyCarePageContainer2-btn1 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 3rem;
    height: 47px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer2-btn2 {
    background-color: #f26828;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 3rem;
    height: 47px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer2-btn3 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 1.5rem;
    height: 47px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer3 {
    padding: 2rem 3px 3rem 3px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    row-gap: 3rem;
    column-gap: 2rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    /* background-image: linear-gradient(135deg, #F26828 60%, #CBE9FF 30%); */
  }

  .elderlyCarePageContainer3-img1 {
    position: absolute;
    z-index: 0;
    left: 0;
  }

  .elderlyCarePageContainer3-img2 {
    position: absolute;
    z-index: 0;
    right: 0;
  }

  .elderlyCarePageContainer3-card {
    height: 22rem;
  }

  .elderlyCarePageContainer3-cardImg {
    width: calc(70%);
  }

  .elderlyCarePageContainer3-cardText1 {
    position: absolute;
    left: calc(10%);
    top: calc(10.5%);
    /* font-family: Poppins; */
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #bcbcbc;
  }

  .elderlyCarePageContainer3-cardHead {
    margin: 0;
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #000000;
  }

  .elderlyCarePageContainer3-cardText2 {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.21804547309875488px;
    text-align: center;
    color: #a4a4a4;
    padding: 0rem 1.5rem;
    margin: 0;
  }

  .elderlyCarePageContainer3-cardB1 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(12.5%);
    left: calc(25%);
  }

  .elderlyCarePageContainer3-cardB2 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(12.5%);
    right: calc(25%);
    rotate: 90deg;
  }

  .elderlyCarePageContainer3-cardB3 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(38%);
    right: calc(25%);
    rotate: 180deg;
  }

  .elderlyCarePageContainer3-cardB4 {
    height: 1.5rem;
    width: 1.5rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(38%);
    left: calc(25%);
    rotate: 270deg;
  }
}

@media (600px <= width <= 1000px) {
  .elderlyCarePageContainer2 {
    position: relative;
  }

  .elderlyCarePageContainer2imgbg {
    width: 260px;
  }

  .elderlyCarePageContainer2-div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    top: 0;
    box-sizing: border-box;
  }

  .elderlyCarePageContainer2-div1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .elderlyCarePageContainer2-div2 {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
  }

  .elderlyCarePageContainer2-div-h {
    font-size: 16px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .elderlyCarePageContainer-checkBox {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }

  .elderlyCarePageContainer2-div-t {
    font-size: 8px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
    width: 100%;
    padding: 0.5rem 0rem;
    margin: 0;
  }

  .elderlyCarePageContainer2-div-t1 {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    padding: 0px 0px 8px 0px;
  }

  .elderlyCarePageContainer2-div-t2 {
    /* font-family: Poppins; */
    font-size: 8px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    margin: 0;
    text-transform: capitalize;
  }

  .elderlyCarePageContainer2-div-t3 {
    /* font-family: Poppins; */
    font-size: 12px;
    font-style: italic;
    font-weight: 275;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0px 0rem 0px 0px;
  }

  .elderlyCarePageContainer2-aligncheckbox {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .elderlyCarePageContainer2-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0rem 0rem;
  }

  .elderlyCarePageContainer2-btn1 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 9px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 1.5rem;
    height: 32px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer2-btn2 {
    background-color: #f26828;
    font-size: 9px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 1rem;
    height: 32px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer2-btn3 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 9px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 1rem;
    height: 32px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer3 {
    padding: 2rem 3px 3rem 3px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    row-gap: 3rem;
    column-gap: 2rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    /* background-image: linear-gradient(135deg, #F26828 60%, #CBE9FF 30%); */
  }

  .elderlyCarePageContainer3-img1 {
    position: absolute;
    z-index: 0;
    left: 0;
  }

  .elderlyCarePageContainer3-img2 {
    position: absolute;
    z-index: 0;
    right: 0;
  }

  .elderlyCarePageContainer3-card {
    height: 17rem;
  }

  .elderlyCarePageContainer3-cardImg {
    width: calc(40px);
  }

  .elderlyCarePageContainer3-cardText1 {
    position: absolute;
    left: calc(10%);
    top: calc(10.5%);
    /* font-family: Poppins; */
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #bcbcbc;
  }

  .elderlyCarePageContainer3-cardHead {
    margin: 0;
    padding: 0rem 0.5rem 0rem 0.5rem;
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #000000;
  }

  .elderlyCarePageContainer3-cardText2 {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.21804547309875488px;
    text-align: center;
    color: #c5c5c5;
    padding: 0rem 0.5rem;
  }

  .elderlyCarePageContainer3-cardB1 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(7.5%);
    left: calc(25%);
  }

  .elderlyCarePageContainer3-cardB2 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(7.5%);
    right: calc(25%);
    rotate: 90deg;
  }

  .elderlyCarePageContainer3-cardB3 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(27%);
    right: calc(25%);
    rotate: 180deg;
  }

  .elderlyCarePageContainer3-cardB4 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(27%);
    left: calc(25%);
    rotate: 270deg;
  }
}

.elderlyCarePageContainer4 {
  margin: 4rem;
}

.elderlyCarePageContainer5 {
  margin: 4rem 0rem;
  position: relative;
  z-index: 0;
}

.elderlyCarePageContainer5-HeadMain {
  /* font-family: Poppins; */
  position: absolute;
  top: calc(16%);
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: #fff;
}

.elderlyCarePageContainer5-TextMain {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.1157514825463295px;
  text-align: center;
  position: absolute;
  top: calc(5%);
  color: #fff;
  width: 100%;
}

.elderlyCarePageContainer5-div {
  position: absolute;
  top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.elderlyCarePageContainer5-div1 {
  position: absolute;
  width: calc(18%);
  left: calc(15%);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.elderlyCarePageContainer5-div11 {
  height: 100%;
  width: 45%;
  cursor: pointer;
}

.elderlyCarePageContainer5-div2 {
  position: absolute;
  width: calc(78%);
  height: 100%;
  right: calc(6%);
  cursor: pointer;
}

.vertical-capsule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.center-animation {
  animation: centerAnimation 1s ease forwards;
  width: auto;
  display: flex;
  justify-content: center;
  position: absolute;
  opacity: 80%;
  top: calc(41.5%);
}

@keyframes centerAnimation {
  from {
    transform: translateX(-100%);
    transition: 5s;
  }
  to {
    transform: translateX(0);
    transition: 5s;
  }
}

.center-animation1 {
  animation: topAnimation 1s ease forwards;
  width: 80%;
  display: flex;
  justify-content: center;
  display: block;
  margin: auto;
}

@keyframes topAnimation {
  from {
    transform: translateY(-100%);
    opacity: 0;
    transition: 10s;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
    transition: 10s;
  }
}

.center-animationHead {
  position: absolute;
  top: calc(36%);
  left: calc(7.5%);
  width: 30%;
  /* font-family: Poppins; */
  font-size: 73.46px;
  font-weight: 700;
  line-height: 110.19px;
  text-align: left;
  color: #ffffff;
  animation: topAnimation1 1s ease forwards;
}

.center-animationText {
  /* font-family: Poppins; */
  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0.1157514825463295px;
  text-align: left;
  color: #ffffff;
  animation: topAnimation1 1s ease forwards;
  position: absolute;
  top: calc(55%);
  left: calc(-7%);
  width: 60%;
}

@keyframes topAnimation1 {
  from {
    transform: translateX(0%);
    opacity: 0;
    transition: 10s;
  }
  to {
    transform: translateX(50%);
    opacity: 1;
    transition: 10s;
  }
}

.capsule {
  width: fit-content;
  height: 489px;
  border-radius: 100px;
}

@media screen and (max-width: 430px) {
  .elderlyCarePageContainer1 {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .elderlyCarePageImg1 {
    width: 100%;
  }

  .elderlyCarePageContainer1Content {
    position: absolute;
    top: 0px;
    height: 100%;
    padding: 0rem 0rem 4rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 0;
    width: 100%;
    background-image: linear-gradient(
      348.7deg,
      #000000 22.56%,
      rgba(0, 0, 0, 0) 75.96%
    );
  }

  .elderlyCarePageContainer1Content-Head {
    color: #ffffff;
    font-weight: 700;
  }

  .elderlyCarePageContainer1Content-text {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.2px;
    padding: 16px 0px;
  }

  .elderlyCarePageContainer1Content-btn {
    background-color: #f26828;
    color: #ffffff;
    width: 9rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    opacity: 1;
    cursor: pointer;
  }

  .elderlyCarePageContainer1Content-btn:hover {
    opacity: 0.8;
  }

  .elderlyCarePageContainer2 {
    position: relative;
  }

  .elderlyCarePageContainer2-div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    top: 0;
    box-sizing: border-box;
    padding: 2rem;
  }

  .elderlyCarePageContainer2imgbg {
    width: 260px;
  }

  .elderlyCarePageContainer2-div1 {
    position: relative;
  }

  .elderlyCarePageContainer2-div2 {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 1rem;
  }

  .elderlyCarePageContainer2-div-h {
    font-size: 15.4px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
  }

  .elderlyCarePageContainer2-div-t {
    font-size: 9px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
    width: 100%;
    padding: 0.7rem 0rem;
    margin: 0;
  }

  .elderlyCarePageContainer2-div-t1 {
    /* font-family: Poppins; */
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    padding: 0px 0px 8px 0px;
  }

  .elderlyCarePageContainer2-div-t2 {
    /* font-family: Poppins; */
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    margin: 0;
    text-transform: capitalize;
  }

  .elderlyCarePageContainer2-div-t3 {
    /* font-family: Poppins; */
    font-size: 9px;
    font-style: italic;
    font-weight: 275;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0px 1rem 0px 0px;
  }

  .elderlyCarePageContainer2-aligncheckbox {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .elderlyCarePageContainer-checkBox {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }

  .elderlyCarePageContainer2-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0rem 0rem;
  }

  .elderlyCarePageContainer2-btn1 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 9px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 2rem;
    height: 23px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer2-btn2 {
    background-color: #f26828;
    font-size: 9px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 1rem;
    height: 23px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer2-btn3 {
    border: 0.6px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 9px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem 0rem;
    height: 23px;
    width: 100px;
    border-radius: 0.5rem;
  }

  .elderlyCarePageContainer-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0rem;
  }

  .elderlyCarePageContainer-toggle1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebf7ff;
    padding: 0.5rem 0.5rem;
    border-radius: 2rem;
    width: auto;
  }

  .elderlyCarePageContainer-toggleActive {
    background-color: #215071;
    width: 150px;
    height: 2rem;
    /* font-family: Poppins; */
    font-size: 11.97px;
    font-weight: 600;
    line-height: 18.46px;
    text-align: center;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
    opacity: 1;
  }

  .elderlyCarePageContainer-toggleUnActive {
    background-color: transparent;
    width: 150px;
    height: 2rem;
    /* font-family: Poppins; */
    font-size: 11.97px;
    font-weight: 600;
    line-height: 18.46px;
    text-align: center;
    color: #215071;
    border-radius: 0.5rem;
    cursor: pointer;
    opacity: 1;
  }

  .elderlyCarePageContainer-toggleActive:hover {
    opacity: 0.8;
  }

  .elderlyCarePageContainer-toggleUnActive:hover {
    opacity: 0.8;
  }

  .elderlyCarePageContainer3 {
    padding: 1rem 0rem 2rem 0rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    /* background-image: linear-gradient(135deg, #F26828 60%, #CBE9FF 30%); */
  }

  .elderlyCarePageContainer3-img1 {
    position: absolute;
    z-index: 0;
    left: 0;
  }

  .elderlyCarePageContainer3-img2 {
    position: absolute;
    z-index: 0;
    right: 0;
  }

  .elderlyCarePageContainer3-card {
    width: calc(45%);
    height: 16rem;
    border-radius: 0.5rem;
    gap: 0rem;
  }

  .elderlyCarePageContainer3-cardImg {
    width: calc(70%);
  }

  .elderlyCarePageContainer3-cardText1 {
    position: absolute;
    left: calc(10%);
    top: calc(10.5%);
    /* font-family: Poppins; */
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #bcbcbc;
  }

  .elderlyCarePageContainer3-cardHead {
    margin: 0;
    padding: 1rem 0rem 0.5rem 0rem;
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.13966697454452515px;
    text-align: center;
    color: #000000;
  }

  .elderlyCarePageContainer3-cardText2 {
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.21804547309875488px;
    text-align: center;
    color: #c5c5c5;
    padding: 0rem 0.5rem;
  }

  .elderlyCarePageContainer3-cardB1 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(8%);
    left: calc(25%);
  }

  .elderlyCarePageContainer3-cardB2 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(8%);
    right: calc(25%);
    rotate: 90deg;
  }

  .elderlyCarePageContainer3-cardB3 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(30%);
    right: calc(25%);
    rotate: 180deg;
  }

  .elderlyCarePageContainer3-cardB4 {
    height: 1rem;
    width: 1rem;
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: #d0d0d0;
    position: absolute;
    top: calc(30%);
    left: calc(25%);
    rotate: 270deg;
  }

  .elderlyCarePageContainer4 {
    margin: 2rem 0rem 1rem 1rem;
  }

  .elderlyCarePageContainer5 {
    margin: 2rem 0rem;
    position: relative;
    z-index: 0;
  }

  .elderlyCarePageContainer5-HeadMain {
    position: absolute;
    top: calc(10%);
    width: 100%;
    /* font-family: Poppins; */
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: #fff;
  }

  .elderlyCarePageContainer5-TextMain {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1157514825463295px;
    text-align: center;
    position: absolute;
    top: calc(3%);
    color: #fff;
    width: 100%;
  }

  .elderlyCarePageContainer5-div {
    position: absolute;
    top: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }

  .elderlyCarePageContainer5-div1 {
    position: absolute;
    width: auto;
    left: auto;
    top: calc(5%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .elderlyCarePageContainer5-div11 {
    height: 100%;
    width: 45%;
    cursor: pointer;
  }

  .elderlyCarePageContainer5-div2 {
    position: absolute;
    width: calc(100%);
    height: 100%;
    right: auto;
    cursor: pointer;
  }

  .vertical-capsule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: auto;
  }

  .center-animation {
    animation: centerAnimation 1s ease forwards;
    width: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(28%);
  }

  @keyframes centerAnimation {
    from {
      transform: translateX(-100%);
      transition: 5s;
    }
    to {
      transform: translateX(0);
      transition: 5s;
    }
  }

  .center-animation1 {
    animation: topAnimation 1s ease forwards;
    width: 90%;
    display: flex;
    justify-content: center;
    display: block;
    margin: auto;
  }

  @keyframes topAnimation {
    from {
      transform: translateY(-100%);
      opacity: 0;
      transition: 10s;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
      transition: 10s;
    }
  }

  .center-animationHead {
    position: absolute;
    top: calc(85%);
    left: calc(20%);
    width: 30%;
    /* font-family: Poppins; */
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    animation: topAnimation1 1s ease forwards;
  }

  .center-animationText {
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1157514825463295px;
    text-align: center;
    color: #ffffff;
    animation: topAnimation1 1s ease forwards;
    position: absolute;
    top: calc(90%);
    left: auto;
    width: calc(50%);
  }

  @keyframes topAnimation1 {
    from {
      transform: translateX(0%);
      opacity: 0;
      transition: 10s;
    }
    to {
      transform: translateX(50%);
      opacity: 1;
      transition: 10s;
    }
  }

  .capsule {
    width: auto;
    border-radius: 16px;
  }
}
