.careModelContainer {
  position: relative;
  padding: 0rem 8rem;
}

.careModelAlign {
  position: absolute;
  top: 35%;
  left: 23%;
  display: flex;
  flex-direction: column;
  height: 14rem;
  justify-content: space-between;
  box-sizing: border-box;
}

.careModelHead {
  /* font-family: Poppins; */
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  color: #fff;
}

.careModelText {
  /* font-family: Poppins; */
  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0.1157514825463295px;
  text-align: left;
  color: #fff;
  width: 70%;
}

@media (1400px <= width <=1500px) {
  .careModelAlign {
    height: 10rem;
  }

  .careModelText {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    width: 70%;
  }
}

@media (1200px <= width <=1300px) {
  .careModelContainer {
    position: relative;
    padding: 0rem 5rem;
  }

  .careModelAlign {
    height: 10rem;
  }

  .careModelText {
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    width: 70%;
  }
}

.careModelContainer1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.careModelMainHead {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  color: #000;
}

.careModelMainText {
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: #000;
  margin: 0;
}

.careModelMain {
  padding: 72px 0px 0px 0px;
}

@media (width <=430px) {
  .careModelHead {
    /* font-family: Poppins; */
    font-size: 16px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    color: #fff;
  }

  .careModelText {
    /* font-family: Poppins; */
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.1157514825463295px;
    text-align: left;
    color: #fff;
    width: 70%;
    margin-top: 0px;
  }

  .careModelAlign {
    position: absolute;
    top: 27%;
    left: 30%;
    height: 9rem;
  }

  .careModelContainer {
    position: relative;
    padding: 0rem 0rem;
  }

  .careModelContainer1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .careModelMain {
    padding: 32px 0px 0px 0px;
  }

  .careModelMainHead {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: #000;
  }

  .careModelMainText {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #000;
    margin: 0;
  }
}
