.career-container {
  padding: 3rem 12rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.careerDiv1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.career-containerHeaD {
  font-size: 80px;
  font-weight: 600;
  line-height: 120px;
  text-align: left;
  color: #215071;
  width: 100%;
}

.alignCareer1 {
  width: 30%;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.alignCareer2 {
  background-color: #215071;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 68%;
  border-radius: 1rem;
  padding: 2rem;
  box-sizing: border-box;
}

.career-icon {
  height: 64px;
  width: 64px;
}

.career-text {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: #fff;
  margin: 0;
}

.career-text1 {
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  text-align: left;
  color: #fff;
  margin: 0;
}

@media (1200px <= width <= 1500px) {
  .career-container {
    padding: 3rem 8rem;
  }
  .career-containerHeaD {
    font-size: 58px;
    line-height: 70px;
  }

  .alignCareer1 {
    width: 35%;
    gap: 0.5rem;
  }

  .alignCareer2 {
    width: 63%;
    gap: 0.5rem;
  }

  .career-icon {
    height: 48px;
    width: 48px;
  }

  .career-text {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .career-text1 {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}

.career-container2 {
  margin: 3rem 12rem;
  display: flex;
  align-items: flex-start;
  border: 0.2px solid #215071;
  border-radius: 10px;
  height: 52rem;
}

.alignCareer3 {
  position: relative;
  width: 50%;
  box-sizing: border-box;
  padding: 4rem 8rem;
  background-color: #f5fbff;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
}

.career-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.career-form-head {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  color: #000;
}

.career-input {
  height: 60px;
  border: 1.02px solid #a4a4a4;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 1rem;
  outline: none;
  background-color: transparent;
  color: #000;

  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.career-input::placeholder {
  color: #a4a4a4;
}

.career-inputA {
  position: absolute;
  width: 20%;
  border-width: 0px 1.02px 0px 0px;
  border-color: #a4a4a4;
  border-style: solid;
  height: 60px;
  border-radius: 10px;
  left: 0;
  top: calc(34%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a4a4a4;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.career-btn {
  height: 60px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  background-color: #215071;
  color: #fff;
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.career-form-label {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #000;
  margin: 0 0 4px 0;
}

.alignCareer4 {
  width: 50%;
  position: relative;
  height: 100%;
  z-index: 0;
}

.alignCareer4-div {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 4rem 8rem;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.career-text2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  color: #fff;
}

@media (1400px <= width <= 1550px) {
  .career-container2 {
    margin: 3rem 8rem;
  }

  .alignCareer3 {
    padding: 3rem 6rem;
  }

  .alignCareer4-div {
    padding: 4rem 6rem;
    gap: 0.5rem;
  }
}

@media (1200px <= width <= 1350px) {
  .career-container2 {
    margin: 3rem 6rem;
  }

  .alignCareer3 {
    padding: 3rem 6rem;
  }

  .alignCareer4-div {
    padding: 4rem 6rem;
    gap: 0.5rem;
  }
}

@media (width < 430px) {
  .career-icon {
    height: 20px;
    width: 20px;
  }

  .career-form-head {
    font-size: 18px;
  }

  .career-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .career-text1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .career-container {
    padding: 3rem 1rem 0rem 1rem;
    gap: 0.8rem;
  }

  .career-container2 {
    margin: 3rem 1rem 0rem 1rem;
    flex-direction: column;
    height: 40rem;
  }

  .alignCareer3 {
    padding: 2rem;
    width: 100%;
    border-radius: 10px;
  }

  .career-btn {
    margin-top: 2rem;
    font-size: 12px;
    line-height: 18px;
  }

  .career-input {
    height: 42px;
    font-size: 12px;
    font-weight: 400;
  }

  .career-inputA {
    height: 42px;
    top: calc(37%);
    font-size: 12px;
  }

  .career-form-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #000;
    margin: 0 0 4px 0;
  }

  .alignCareer4-div {
    padding: 2rem 3rem;
    box-sizing: border-box;
    gap: 0rem;
  }

  .career-text2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #fff;
  }
}

.alignCareer5 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.careerDivider {
  height: 1px;
  width: calc(30%);
  background-color: #a4a4a4;
  margin: 2rem 0rem;
}

.careerDivider-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #a4a4a4;
}

.alignCareer6 {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  margin-bottom: 2rem;
}

.mobileCareer-align {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.mobileCareer-1 {
  width: 20%;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #215071;
}

.mobileCareer-2 {
  width: 35%;
  background-color: #215071;
  height: 9rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem;
  border-radius: 10px;
  box-sizing: border-box;
}

.mobileCareer-3 {
  width: 35%;
  background-color: #e77947;
  height: 9rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 10px;
}
