.contactUsPage {
  padding: 8rem 13rem 4rem 13rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.contactUsPage-head1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.contactUsPage-div1 {
  width: calc(50%);
  background-color: #f5fbff;
  border: 1px double #215071;
  padding: 2.8rem 6rem;
  border-radius: 1rem 0rem 0rem 1rem;
}

.contactUsPage-formMain {
  padding: 1rem 0rem;
  width: 100%;
}

.contactUsPage-form {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  width: 100%;
}

.contactUsPage-form2 {
  display: flex;
  flex-direction: column;
  padding: 3rem 0rem;
  width: 100%;
  gap: 1rem;
}

.contactUsPage-form1 {
  display: flex;
  flex-direction: column;
  width: calc(48%);
  gap: 0.5rem;
  position: relative;
}

.contactUsPage-form1A {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  gap: 0.5rem;
  position: relative;
}

.contactUsPage-formLabel {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.contactUsPage-formInput {
  border: 1px double #a4a4a4;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.5rem;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  background-color: #f5fbff;
}

.contactUsPage-formInput1Div {
  border: 1px double #a4a4a4;
  box-sizing: border-box;
  border-radius: 0.5rem;
  outline: none;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
}

.contactUsPage-formInput1Div-code {
  font-size: 15px;
  color: #a4a4a4;
}

.contactUsPage-formInput1Div1 {
  border-right: 1px solid #a4a4a4;
  box-sizing: border-box;
  border-radius: 0.5rem;
  outline: none;
  height: 100%;
  width: calc(25%);
  background-color: #f5fbff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.2rem;
}

.contactUsPage-formInput1Div1A {
  border-right: 1px solid #a4a4a4;
  box-sizing: border-box;
  border-radius: 0.5rem;
  outline: none;
  height: 100%;
  width: calc(12%);
  background-color: #f5fbff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0rem 0.2rem;
}

.contactUsPage-rightDivText {
  margin: 0;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.contactUsPage-div2-div {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 2.8rem 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (1380px <= width <=1560px) {
  .contactUsPage {
    padding: 8rem 10rem 4rem 10rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .contactUsPage-div1 {
    width: calc(50%);
    background-color: #f5fbff;
    border: 1px double #215071;
    padding: 2.8rem 4rem;
  }

  .contactUsPage-formMain {
    padding: 1rem 0rem;
    width: 100%;
  }

  .contactUsPage-form {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    width: 100%;
  }

  .contactUsPage-form2 {
    display: flex;
    flex-direction: column;
    padding: 3rem 0rem;
    width: 100%;
    gap: 1rem;
  }

  .contactUsPage-form1 {
    display: flex;
    flex-direction: column;
    width: calc(48%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-form1A {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-formLabel {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .contactUsPage-formInput1Div-code {
    font-size: 15px;
  }

  .contactUsPage-formInput1Div1 {
    border-right: 1px solid #a4a4a4;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 100%;
    width: calc(25%);
    background-color: #f5fbff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.2rem;
  }

  .contactUsPage-formInput1Div1A {
    border-right: 1px solid #a4a4a4;
    width: calc(18%);
  }

  .contactUsPage-div2-div {
    padding: 2.8rem 4rem;
  }

  .contactUsPage-rightDivText {
    font-size: 19px;
    font-weight: 500;
  }

  .contactUsPage-head1 {
    font-size: 28px;
    line-height: 38px;
    width: 100%;
  }
}

@media (1024px <= width <= 1300px) {
  .contactUsPage {
    padding: 8rem 6rem 4rem 6rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .contactUsPage-div1 {
    width: calc(50%);
    background-color: #f5fbff;
    border: 1px double #215071;
    padding: 2.8rem 4rem;
  }

  .contactUsPage-rightDivText {
    font-size: 18px;
    line-height: 33px;
  }

  .contactUsPage-formMain {
    padding: 1rem 0rem;
    width: 100%;
  }

  .contactUsPage-form {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    width: 100%;
  }

  .contactUsPage-form2 {
    display: flex;
    flex-direction: column;
    padding: 3rem 0rem;
    width: 100%;
    gap: 1rem;
  }

  .contactUsPage-form1 {
    display: flex;
    flex-direction: column;
    width: calc(48%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-form1A {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-formLabel {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .contactUsPage-formInput1Div-code {
    font-size: 15px;
  }

  .contactUsPage-formInput1Div1 {
    border-right: 1px solid #a4a4a4;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 100%;
    width: calc(25%);
    background-color: #f5fbff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.2rem;
  }

  .contactUsPage-formInput1Div1A {
    width: calc(18%);
  }

  .contactUsPage-div2-div {
    padding: 2.8rem 4rem;
  }

  .contactUsPage-head1 {
    font-size: 26px;
    line-height: 38px;
    width: 100%;
  }
}

@media (600px <= width <= 1000px) {
  .contactUsPage {
    padding: 8rem 6rem 4rem 6rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .contactUsPage-div1 {
    width: calc(50%);
    background-color: #f5fbff;
    border: 1px double #215071;
    padding: 2rem;
  }

  .contactUsPage-rightDivText {
    margin: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  .contactUsPage-formMain {
    padding: 1rem 0rem;
    width: 100%;
  }

  .contactUsPage-form {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    width: 100%;
  }

  .contactUsPage-form2 {
    display: flex;
    flex-direction: column;
    padding: 3rem 0rem;
    width: 100%;
    gap: 1rem;
  }

  .contactUsPage-form1 {
    display: flex;
    flex-direction: column;
    width: calc(48%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-form1A {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-formLabel {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .contactUsPage-formInput1Div-code {
    font-size: 15px;
  }

  .contactUsPage-formInput1Div1 {
    border-right: 1px solid #215071;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 100%;
    width: calc(25%);
    background-color: #f5fbff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.2rem;
  }

  .contactUsPage-formInput1Div1A {
    width: calc(18%);
  }
}

.contactUsPage-formInput1Div1AB {
  border-right: 1px solid #215071;
  box-sizing: border-box;
  border-radius: 0.5rem;
  outline: none;
  height: 100%;
  width: calc(20%);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem;
}

.contactUsPage-formInput1 {
  box-sizing: border-box;
  padding: 0.3rem 0.4rem;
  width: calc(75%);
  border-radius: 0.5rem;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.09em;
  text-align: left;
  color: #000000;
}

.contactUsPage-formInput1AA {
  box-sizing: border-box;
  padding: 0.3rem 0.4rem;
  width: calc(90%);
  border-radius: 0.5rem;
  outline: none;
  background-color: #f5fbff;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.09em;
  text-align: left;
  color: #000000;
}

.contactUsPage-formInput1AB {
  box-sizing: border-box;
  padding: 0.3rem 0.75rem;
  width: 30rem;
  border-radius: 0.5rem;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.09em;
  text-align: left;
  color: #000000;
}

@media (width <= 1300px) {
  .contactUsPage-formInput1AB {
    width: 20rem;
  }
}

.contactUsPage-formInput1Div2 {
  border: 1px double #215071;
  box-sizing: border-box;
  border-radius: 0.5rem;
  outline: none;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
  background-color: #fff;
  align-items: center;
  opacity: 1;
  cursor: pointer;
}

.contactUsPage-calendar {
  position: absolute;
  top: 4rem;
  z-index: 1;
}

.contactUsPage-Time {
  width: 90%;
  border-radius: 1rem 0rem;
  height: 12rem;
  background-color: #ffffff;
  position: absolute;
  top: 4rem;
  border: 0.4px solid #215071;
  box-sizing: border-box;
  padding: 1rem 0rem;
}

.contactUsPage-TimeHead {
  text-align: center;
  text-decoration: underline #000000;
  text-underline-offset: 0.4em;
  text-decoration-thickness: 0px;
  font-size: 1.2rem;
  font-weight: 500;
}

.contactUsPage-TimeHead-align {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.8rem;
}

.contactUsPage-Btn {
  width: 100%;
  background-color: #215071;
  /* font-family: Poppins; */
  font-size: 19px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin: 1rem 0rem;
  padding: 1rem 0rem;
  border-radius: 1rem;
}

.contactUsPage-formInput1Div2:hover {
  opacity: 0.8;
}

.contactUsPage-div2 {
  width: calc(50%);
  background-color: #215071;
  position: relative;
  border-radius: 0rem 1rem 1rem 0rem;
}

.contactUsPage-div2-divdiv {
  padding: 4rem 0rem;
}

/* 
.contactUsPage-head11 {
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
} */

.contactUsPage-text1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
  color: #838383;
}

.contactUsPage-text11 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
  color: #ffffff;
}

.contactUsPage-rightDiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0rem;
}

.contactUsPage-rightDiv1 {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.contactUsPage-iconDiv {
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem 0rem;
}

.contactUsPage-icons {
  padding: 4px;
  background-color: #fff;
  border-radius: 100%;
  font-size: 0.5rem;
  cursor: pointer;
  opacity: 1;
}

.contactUsPage-icons:hover {
  opacity: 0.8;
}

.contactUsPage-dialog {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: relative;
}

.contactUsPage-dialogClose {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.contactUsPage-dialogHead {
  /* font-family: Poppins; */
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.contactUsPage-dialogText {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #c5c5c5;
  margin: 0;
}

.contactUsPage-dialogBoxs {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
}

.contactUsPage-dialogInput {
  /* font-family: Poppins; */
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: #000000;
  background-color: #efefef;
  width: calc(10%);
  border-radius: 0.5rem;
  outline: none;
  padding: 4px;
  box-sizing: border-box;
}

.contactUsPage-dialogText1 {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.contactUsPage-dialogText1:hover {
  opacity: 0.8;
}

.contactUsPage-dialogBtn {
  background-color: #f26828;
  height: 3rem;
  width: 40%;
  /* font-family: Poppins; */
  font-size: 19.22px;
  font-weight: 600;
  line-height: 28.83px;
  text-align: center;
  color: #ffffff;
  border-radius: 1rem;
}

.contact-brochure {
  background-color: #f26828;
  width: 340px;
  height: 62px;
  display: block;
  margin: 16px auto;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  z-index: 999;
}

@media only screen and (max-width: 850px) {
  .contact-brochure {
    background-color: #f26828;
    width: 200px;
    height: 36px;
    display: block;
    margin: 32px auto;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #fff;
    border-radius: 8px;
  }

  .contactUsPage {
    padding: 0rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 1rem;
  }

  .contactUsPage-div1 {
    width: calc(100%);
    background-color: #f5fbff;
    border: 1px double #daeefc;
    padding: 1rem;
    border-radius: 1rem;
  }

  .contactUsPage-formMain {
    padding: 0rem 0rem;
    width: 100%;
  }

  .contactUsPage-form {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    width: 100%;
  }

  .contactUsPage-form2 {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    width: 100%;
    gap: 1rem;
  }

  .contactUsPage-form1 {
    display: flex;
    flex-direction: column;
    width: calc(48%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-form1A {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    gap: 0.5rem;
    position: relative;
  }

  .contactUsPage-formLabel {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .contactUsPage-formInput {
    padding: 0.5rem 0.4rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  .contactUsPage-formInput1Div {
    height: 2.5rem;
  }

  .contactUsPage-formInput1Div-code {
    font-size: 12px;
  }

  .contactUsPage-formInput1Div1 {
    border-right: 1px solid #215071;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 100%;
    width: calc(30%);
    background-color: #f5fbff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.2rem;
  }

  .contactUsPage-formInput1Div1A {
    width: calc(20%);
  }

  .contactUsPage-formInput1Div1AB {
    border-right: 1px solid #a4a4a4;
    box-sizing: border-box;
    border-radius: 0.3rem;
    outline: none;
    height: 100%;
    width: calc(30%);
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.5rem;
  }

  .contactUsPage-formInput1 {
    box-sizing: border-box;
    padding: 0.3rem 0.4rem;
    width: calc(75%);
    border-radius: 0.5rem;
    outline: none;

    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.09em;
    text-align: left;
    color: #000000;
  }

  .contactUsPage-formInput1AA {
    box-sizing: border-box;
    padding: 0.3rem 0.4rem;
    width: calc(90%);
    border-radius: 0.5rem;
    outline: none;

    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.09em;
    text-align: left;
    color: #000000;
  }

  .contactUsPage-formInput1AB {
    box-sizing: border-box;
    padding: 0rem 0.4rem;
    width: 6rem;
    border-radius: 0.5rem;
    outline: none;
    font-size: 10px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.09em;
    text-align: left;
    color: #000000;
  }

  .contactUsPage-formInput1Div2 {
    border: 1px double #215071;
    box-sizing: border-box;
    border-radius: 0.5rem;
    outline: none;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.5rem;
    background-color: #fff;
    align-items: center;
    opacity: 1;
    cursor: pointer;
  }

  .contactUsPage-calendar {
    position: absolute;
    top: 4rem;
    z-index: 1;
  }

  .contactUsPage-Time {
    width: 100%;
    border-radius: 1rem 0rem;
    height: 9rem;
    background-color: #ffffff;
    position: absolute;
    top: 4rem;
    border: 0.4px solid #215071;
    box-sizing: border-box;
    padding: 1rem 0rem;
  }

  .contactUsPage-TimeHead {
    text-align: center;
    text-decoration: underline #000000;
    text-underline-offset: 0.4em;
    text-decoration-thickness: 0px;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .contactUsPage-TimeHead-align {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 0.8rem;
  }

  .contactUsPage-Btn {
    width: 100%;
    background-color: #215071;
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    margin: 1rem 0rem;
    padding: 0.8rem 0rem;
    border-radius: 0.5rem;
  }

  .contactUsPage-formInput1Div2:hover {
    opacity: 0.8;
  }

  .contactUsPage-div2 {
    width: calc(50%);
    background-color: #215071;
    position: relative;
    display: none;
  }

  .contactUsPage-div2-div {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 2rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contactUsPage-div2-divdiv {
    padding: 4rem 0rem;
  }

  .contactUsPage-head1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 60%;
    color: #000000;
  }

  /* .contactUsPage-head11 {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  } */

  .contactUsPage-text1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: justify;
    color: #838383;
    width: 80%;
  }

  .contactUsPage-text11 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justify;
    color: #ffffff;
  }

  .contactUsPage-rightDiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0rem;
  }

  .contactUsPage-rightDiv1 {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .contactUsPage-rightDivText {
    margin: 0;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  .contactUsPage-iconDiv {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0rem;
  }

  .contactUsPage-icons {
    padding: 4px;
    background-color: #fff;
    border-radius: 100%;
    font-size: 0.5rem;
    cursor: pointer;
    opacity: 1;
  }

  .contactUsPage-icons:hover {
    opacity: 0.8;
  }

  .contactUsPage-dialog {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    position: relative;
  }

  .contactUsPage-dialogClose {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }

  .contactUsPage-dialogHead {
    /* font-family: Poppins; */
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
    color: #000000;
    margin: 0;
  }

  .contactUsPage-dialogText {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    color: #c5c5c5;
    margin: 0;
  }

  .contactUsPage-dialogBoxs {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
  }

  .contactUsPage-dialogInput {
    /* font-family: Poppins; */
    font-size: 22px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #000000;
    background-color: #efefef;
    width: calc(12%);
    border-radius: 0.5rem;
    outline: none;
    padding: 4px;
    box-sizing: border-box;
  }

  .contactUsPage-dialogText1 {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: #000000;
    cursor: pointer;
  }

  .contactUsPage-dialogText1:hover {
    opacity: 0.8;
  }

  .contactUsPage-dialogBtn {
    background-color: #f26828;
    height: 3rem;
    width: 40%;
    /* font-family: Poppins; */
    font-size: 19.22px;
    font-weight: 600;
    line-height: 28.83px;
    text-align: center;
    color: #ffffff;
    border-radius: 1rem;
  }

  .contact-whatsapp {
    position: absolute;
    top: calc(4%);
    right: calc(4%);
  }

  .contact-whatsapp1 {
    font-family: Poppins;
    font-size: 8.23px;
    font-weight: 500;
    line-height: 12.34px;
    text-align: right;
    color: #215071;
    margin: 0;
    padding-bottom: 4px;
  }

  .contact-whatsapp-div {
    border: 1px solid #215071;
    border-radius: 0.5rem;
    padding: 0.4rem 0rem;
    width: 88px;
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
  }

  .contact-whatsapp-div1 {
    font-family: Poppins;
    font-size: 8.96px;
    font-weight: 600;
    line-height: 13.44px;
    text-align: left;
    color: #215071;
    margin: 0;
  }
}
