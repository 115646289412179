.blogPageDetailsContainer {
  padding: 1rem 10rem;
  box-sizing: border-box;
}

.blogPageDetails-Head {
  /* font-family: Poppins; */
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
  color: #000000;
  padding: 2rem 0rem 1rem 0rem;
}

.blogPageDetailsContainer-divL {
  position: absolute;
  left: calc(5%);
  top: calc(20%);
}

.blogPageDetailsContainer-divR {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  width: auto;
}

.blogPageDetails-align1 {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0rem;
}

.blogPageDetails-dName {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin: 0;
}

.blogPageDetails-designation {
  /* font-family: Poppins; */
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.blogPageDetails-pDateNTime {
  /* font-family: Poppins; */
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ababab;
}

.blogPageDetails-contentHead {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.blogPageDetails-content1 {
  /* font-family: Poppins; */
  font-size: 18px;
  line-height: 27px;
 
}

.blogPageDetails-contentDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 1rem 0rem;
}

.blogPageDetails-contentDiv1 {
  width: calc(49%);
  box-sizing: border-box;
  padding: 0rem;
}

@media screen and (max-width: 1280px) {
  .blogPageDetailsContainer-divL {
    position: absolute;
    left: calc(5%);
    top: calc(25%);
  }
}

@media screen and (max-width: 430px) {
  .blogPageDetailsContainer {
    padding: 3rem 1rem;
    box-sizing: border-box;
  }

  .blogPageDetails-Head {
    /* font-family: Poppins; */
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: #000000;
    padding: 2rem 0rem 1rem 0rem;
  }

  .blogPageDetailsContainer-divL {
    position: absolute;
    left: calc(5%);
    top: calc(8%);
  }

  .blogPageDetailsContainer-divR {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    width: 90%;
    margin: auto 0px auto 32px;
  }

  .blogPageDetails-align1 {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0rem;
  }

  .blogPageDetails-dName {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin: 0;
  }

  .blogPageDetails-designation {
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    color: #9e9e9e;
  }

  .blogPageDetails-pDateNTime {
    /* font-family: Poppins; */
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ababab;
  }

  .blogPageDetails-contentHead {
    /* font-family: Poppins; */
    font-size: 16px;
    line-height: 20px;
  }

  .blogPageDetails-content1 {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .blogPageDetails-contentDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 1rem 0rem;
  }

  .blogPageDetails-contentDiv1 {
    width: calc(49%);
    box-sizing: border-box;
    padding: 0rem;
  }
}
