.careGiverPageContainer1 {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.careGiverPageImg1 {
  width: 100%;
}

.careGiverPageContainer1Content {
  position: absolute;
  top: 0px;
  height: 100%;
  padding: 0rem 0rem 4rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 0;
  width: 100%;
  background-image: linear-gradient(
    348.7deg,
    #000000 22.56%,
    rgba(0, 0, 0, 0) 75.96%
  );
}

.careGiverPageContainer1Content-Head {
  color: #ffffff;
  font-weight: 700;
}

.careGiverPageContainer1Content-text {
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.2px;
  padding: 16px 0px;
}

.careGiverPageContainer1Content-btn {
  background-color: #f26828;
  color: #ffffff;
  width: 9rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  font-size: 0.8rem;
  opacity: 1;
  cursor: pointer;
}

.careGiverPageContainer1Content-btn:hover {
  opacity: 0.8;
}

.careGiverPageContainr-heads {
  /* font-family: Poppins; */
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  padding: 2rem 0rem;
  color: #000000;
}

.careGiverPageContainer2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 2rem 0rem;
  z-index: 1;
}

.careGiverPageContainer2-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  row-gap: 4rem;
  column-gap: 2rem;
  padding: 3rem 8rem;
  position: relative;
}

.careGiverPageContainer2-div1 {
  position: relative;
  width: calc(25%);
  box-sizing: border-box;
}

.careGiverPageContainer2-div2 {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.careGiverPageContainer2-div2-div {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 24px 0px 0px 0px;
}

.careGiverPageContainer2-div-h {
  font-size: 24px;
  font-weight: 600;
  line-height: 39.87px;
  letter-spacing: 0.1741749495267868px;
  text-align: left;
  color: #fff;
}

.careGiverPageContainer2-checkbox {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.careGiverPageContainer2-div-t {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.19658511877059937px;
  text-align: left;
  color: #ffffff;
  width: 80%;
  padding: 1rem 0rem;
}

.careGiverPageContainer2-div-t1 {
  /* font-family: Poppins; */
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.22962571680545807px;
  text-align: left;
  color: #ffffff;
  padding: 0px 0px 8px 0px;
}

.careGiverPageContainer2-div-t2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 29.24px;
  letter-spacing: 0.20344901084899902px;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-transform: capitalize;
  width: 85%;
}

.careGiverPageContainer2-div-t3 {
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: italic;
  font-weight: 275;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0px 7rem 0px 0px;
}

.careGiverPageContainer2-aligncheckbox {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.careGiverPageContainer2-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem 4rem 0rem;
}

.careGiverPageContainer2-btn1 {
  border: 1px solid #ffffff;
  /* font-family: Poppins; */
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0rem 3rem;
  border-radius: 0.5rem;
  height: 42px;
}

@media (1400px <= width <= 1500px) {
  .careGiverPageContainer2-div {
    row-gap: 2rem;
    column-gap: 2rem;
    padding: 3rem 6rem;
  }

  .careGiverPageContainer2-div1 {
    width: 28%;
  }

  .careGiverPageContainer2-div2-div {
    height: 200px;
    padding: 16px 0px 0px 0px;
    gap: 0rem;
  }

  .careGiverPageContainer2-div-h {
    font-size: 20px;
    line-height: 29.87px;
  }

  .careGiverPageContainer2-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .careGiverPageContainer2-div-t2 {
    font-size: 14px;
    font-weight: 500;
    width: 90%;
  }
}

@media (1200px <= width <= 1300px) {
  .careGiverPageContainer2-div {
    row-gap: 2rem;
    column-gap: 2rem;
    padding: 3rem 6rem;
  }

  .careGiverPageContainer2-div1 {
    width: 30%;
  }

  .careGiverPageContainer2-div2-div {
    height: 190px;
    padding: 16px 0px 0px 0px;
  }

  .careGiverPageContainer2-div-h {
    font-size: 18px;
    line-height: 25.87px;
  }

  .careGiverPageContainer2-checkbox {
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }

  .careGiverPageContainer2-div-t2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    width: 90%;
  }
  .careGiverPageContainer2-btn1 {
    font-size: 12px;
  }
}

.careGiverPageContainer3 {
  padding: 2rem 10rem 3rem 10rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

.careGiverPageContainer3-img1 {
  position: absolute;
  z-index: 0;
  left: 0;
}

.careGiverPageContainer3-img2 {
  position: absolute;
  z-index: 0;
  right: 0;
}

.careGiverPageContainer3-card {
  width: 25%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1rem;
}

.careGiverPageContainer3-card-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.careGiverPageContainer3-card-div1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: calc(40%);
}

.careGiverPageContainer3-cardImg {
  position: absolute;
  top: calc(3%);
  height: auto;
  width: 100%;
  box-sizing: border-box;
}

.careGiverPageContainer3-cardImg1 {
  width: 100%;
}

.careGiverPageContainer3-cardHead {
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.13966697454452515px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.careGiverPageContainer3-cardText2 {
  /* font-family: Poppins; */
  font-size: 1rem;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.21804547309875488px;
  text-align: center;
  color: #000000;
  width: 80%;
  margin: 0;
}

@media (1380px <= width <=1500px) {
  .careGiverPageContainer3-card {
    width: 30%;
  }
  .careGiverPageContainer3 {
    padding: 2rem 8rem 3rem 8rem;
  }

  .careGiverPageContainer3-card-div1 {
    gap: 0.75rem;
    top: calc(39.5%);
  }

  .careGiverPageContainer3-cardHead {
    font-size: 20px;
    line-height: 30px;
  }

  .careGiverPageContainer3-cardText2 {
    font-size: 14px;
    line-height: 22px;
    width: 80%;
  }

  .physioPageContainer3-cardImg {
    width: 84px;
  }
}

@media (1180px <= width <=1300px) {
  .careGiverPageContainer3-card {
    width: 28%;
  }
  .careGiverPageContainer3 {
    padding: 3rem;
    row-gap: 3rem;
  }

  .physioPageContainer3-cardImg {
    width: 72px;
  }

  .careGiverPageContainer3-card-div1 {
    top: calc(39%);
    gap: 0.75rem;
  }

  .careGiverPageContainer3-cardHead {
    font-size: 17px;
    line-height: 30px;
  }

  .careGiverPageContainer3-cardText2 {
    font-size: 14px;
    line-height: 22px;
    width: 80%;
  }
}

@media (width <= 430px) {
  .careGiverPageContainer3-card {
    width: 46.5%;
  }
  .careGiverPageContainer3 {
    padding: 2rem 0rem;
    row-gap: 2rem;
    column-gap: 0.5rem;
  }

  .careGiverPageContainer3-card-div1 {
    top: calc(28%);
    gap: 0.5rem;
  }

  .careGiverPageContainer3-cardHead {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 70%;
  }

  .careGiverPageContainer3-cardText2 {
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    width: 80%;
  }
}

.careGiverPageContainer4 {
  margin: 4rem;
}

.careGiverPageContainer5 {
  margin: 4rem 0rem;
  position: relative;
  z-index: 0;
}

.careGiverPageContainer5-div {
  position: absolute;
  top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.careGiverPageContainer5-img {
  display: block;
  margin: auto;
}

.careGiverPageContainer5-div1 {
  position: absolute;
  width: calc(12%);
  left: calc(5.9%);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.careGiverPageContainer5-div11 {
  height: 100%;
  width: 45%;
  cursor: pointer;
}

.careGiverPageContainer5-div2 {
  position: absolute;
  width: calc(76%);
  height: 100%;
  right: calc(6%);
  cursor: pointer;
}

@media screen and (max-width: 430px) {


  .careGiverPageContainer1 {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .careGiverPageImg1 {
    width: 100%;
  }

  .careGiverPageContainer1Content {
    position: absolute;
    top: 0px;
    height: 100%;
    padding: 0rem 0rem 4rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 0;
    width: 100%;
    background-image: linear-gradient(
      348.7deg,
      #000000 22.56%,
      rgba(0, 0, 0, 0) 75.96%
    );
  }

  .careGiverPageContainer1Content-Head {
    color: #ffffff;
    font-weight: 700;
  }

  .careGiverPageContainer1Content-text {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.2px;
    padding: 16px 0px;
  }

  .careGiverPageContainer1Content-btn {
    background-color: #f26828;
    color: #ffffff;
    width: 9rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    opacity: 1;
    cursor: pointer;
  }

  .careGiverPageContainer1Content-btn:hover {
    opacity: 0.8;
  }

  .careGiverPageContainr-heads {
    /* font-family: Poppins; */
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    padding: 2rem 0rem;
    color: #000000;
  }

  .careGiverPageContainer2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 2rem 0rem;
  }

  .careGiverPageContainer2-div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    gap: 1rem;
    padding: 1rem 0.5rem;
  }

  .careGiverPageContainer2-div1 {
    position: relative;
    width: calc(46%);
    box-sizing: border-box;
  }

  .careGiverPageContainer2-div2 {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 1rem;
  }

  .careGiverPageContainer2-div2-div {
    width: 100%;
    height: 144px;
    /* background-color: #000000; */
    display: flex;
    flex-direction: column;
    gap: 0rem;
    padding: 0px 0px 0px 0px;
  }

  .careGiverPageContainer2-div-h {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1741749495267868px;
    text-align: left;
    color: #fff;
    height: 48px;
  }

  .careGiverPageContainer2-div-t {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.19658511877059937px;
    text-align: left;
    color: #ffffff;
    width: 80%;
    padding: 1rem 0rem;
  }

  .careGiverPageContainer2-div-t1 {
    /* font-family: Poppins; */
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.22962571680545807px;
    text-align: left;
    color: #ffffff;
    padding: 0px 0px 8px 0px;
  }

  .careGiverPageContainer2-div-t2 {
    font-size: 9px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.20344901084899902px;
    text-align: left;
    color: #ffffff;
    margin: 0;
    text-transform: capitalize;
    width: 90%;
  }

  .careGiverPageContainer2-div-t3 {
    /* font-family: Poppins; */
    font-size: 14px;
    font-style: italic;
    font-weight: 275;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0px 7rem 0px 0px;
  }

  .careGiverPageContainer2-aligncheckbox {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0.2rem 0rem;
  }

  .careGiverPageContainer2-checkbox {
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }

  .careGiverPageContainer2-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 0rem;
  }

  .careGiverPageContainer2-btn1 {
    border: 1px solid #ffffff;
    /* font-family: Poppins; */
    font-size: 10px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    padding: 0rem;
    width: 100%;
    border-radius: 0.5rem;
  }

  .careGiverPageContainer4 {
    margin: 4rem;
  }

  .careGiverPageContainer5 {
    margin: 4rem 0rem;
    position: relative;
    z-index: 0;
  }

  .careGiverPageContainer5-div {
    position: absolute;
    top: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .careGiverPageContainer5-img {
    display: block;
    margin: auto;
  }

  .careGiverPageContainer5-div1 {
    position: absolute;
    width: calc(12%);
    left: calc(5.9%);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .careGiverPageContainer5-div11 {
    height: 100%;
    width: 45%;
    cursor: pointer;
  }

  .careGiverPageContainer5-div2 {
    position: absolute;
    width: calc(76%);
    height: 100%;
    right: calc(6%);
    cursor: pointer;
  }
}
