.event-container {
  padding: 2rem 12rem 4rem 12rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}

.event-containerhead {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: #000;
  padding: 4rem 12rem 0rem 12rem;
}

.event-BoxBorder {
  width: 100%;
  padding: 1.5rem;
  transition: 0.5s;
  border: 1px solid #215071;
  border-radius: 0.5rem;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.event-Box {
  width: 100%;
  padding: 1.5rem;
  transition: 0.5s;
  border-radius: 0.5rem;
  height: 80px;
  /* transition: 0.5s ease-out; */
  cursor: pointer;
  margin-bottom: 16px;
}

.event-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-head {
  font-size: 1.375rem;
  color: #000000;
  font-weight: 500;
  padding: 0.4rem 0rem;
}

.event-text {
  color: #000;
  font-weight: 400;
  font-size: 1.125rem;
  width: 95%;
}

.event-alignImage {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
}

.event-alignImage1 {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.event-Image {
  width: 30%;
}

.event-alignImage2 {
  display: flex;
  align-items: center;
  width: 500px;
  margin: 1rem 0rem;
}

.event-text1 {
  font-size: 13.78px;
  font-weight: 500;
  line-height: 20.67px;
  text-align: left;
  color: #a4a4a4;
}

.event-icon {
  width: auto;
  height: auto;
}

@media (width < 430px) {
  .event-container {
    padding: 2rem 1rem 4rem 1rem;
    gap: 1.5rem;
  }

  .event-containerhead {
    font-size: 14px;
    line-height: 21px;
    padding: 2rem 1rem 0rem 1rem;
  }

  .event-BoxBorder {
    width: 100%;
    padding: 1rem;
  }

  .event-Box {
    width: 100%;
  }

  .event-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .event-head {
    font-size: 14px;
    line-height: 21px;
    padding: 0.4rem 0rem;
  }

  .event-text {
    font-size: 12px;
    line-height: 18px;
    width: 95%;
  }

  .event-alignImage {
    gap: 0rem;
    justify-content: space-between;
  }

  .event-alignImage2 {
    width: auto;
    margin: 0.5rem 0rem;
  }

  .event-text1 {
    font-size: 12px;
    line-height: 18px;
  }

  .event-icon {
    width: 20px;
    height: 20px;
  }
}
