.headerContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  padding: 32px 80px;
  box-shadow: 0px 0px 25px 2px #00000012;
  z-index: 999;
}

.headerContainerA {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background-color: #ffffff;
  position: sticky;
  top: -100px;
  padding: 32px 64px;
  box-shadow: 0px 0px 25px 2px #00000012;
  z-index: 999;
  transition: 0.3s;
}

.headerLogo {
  width: 64px;
  height: 64px;
}

.headerLabels {
  display: flex;
  gap: 3.5rem;
  align-items: center;
  justify-content: center;
}

.headerLabel {
  margin: 0;
  font-weight: 600;
  font-size: 1.375rem;
  cursor: pointer;
  letter-spacing: 0.02px;
  opacity: 1;
}

.optionHeaderDiv {
  width: calc(78%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.optionHeaderLabel {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.2px;
  opacity: 1;
  color: #ffffff;
}

@media (1024px <= width <= 1440px) {
  .headerLabels {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }

  .headerLabel {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    letter-spacing: 0.02px;
    opacity: 1;
  }

  .optionHeaderLabel {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    letter-spacing: 0.2px;
    opacity: 1;
    color: #ffffff;
  }

  .optionHeaderDiv {
    width: calc(74%);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.headerLabel:hover {
  opacity: 0.8;
}

.headerBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px double #7e97c5;
  padding: 6px 16px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.headerBtn:hover {
  opacity: 0.8;
}

.optionHeader {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: #215071;
  position: sticky;
  top: 72px;
  padding: 16px 80px;
  z-index: 998;
}

.optionHeaderA {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: #215071;
  position: sticky;
  top: 0px;
  padding: 16px 80px;
  z-index: 998;
  transition: 0.3s;
}

.optionHeaderLabel:hover {
  opacity: 0.8;
}

.header-cityDialog {
  height: 18.5rem;
  width: 15rem;
  background-color: #f5f6f5;
  box-shadow: 0px 0px 25px 2px #00000012;
  position: fixed;
  top: 3rem;
  right: 3rem;
  z-index: 999;
  border-radius: 14px 0px 14px 14px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.header-serviceDialog {
  height: 12.5rem;
  width: 15rem;
  background-color: #f5f6f5;
  box-shadow: 0px 0px 25px 2px #00000012;
  position: fixed;
  top: 3.2rem;
  left: calc(48.2%);
  z-index: 999;
  border-radius: 14px 0px 14px 14px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.header-alignCity {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
  cursor: pointer;
  color: #215071;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: right;
}

.header-alignCity:hover {
  background-color: #e9e9e9;
  cursor: pointer;
  color: #f26828;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: right;
}

.headerContainerIcons {
  padding: 4px;
  background-color: #215071;
  border-radius: 100%;
  font-size: 0.5rem;
  cursor: pointer;
  opacity: 1;
}
