.scrollButton {
  position: fixed;
  transition: 1s;
  bottom: 0px;
  right: calc(4.99%);
  cursor: pointer;
  z-index: 999;
}

.chatBox {
  height: 26rem;
  width: 22rem;
  background-color: #f4f4f7;
  position: fixed;
  transition: 1s;
  bottom: 0px;
  right: calc(4.99%);
  cursor: pointer;
  border-radius: 1rem 1rem 0rem 0rem;
  z-index: 999;
}

.chatBox1 {
  width: 100%;
  height: 80%;
  position: relative;
  display: flex;
}

.chatBox11 {
  width: 10%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 0px 1rem 0.5rem;
  background-color: #e9d9d9;
  border-radius: 16px 0px 0px 0px;
}

.chatBox12 {
  width: 90%;
  background-color: #e9d9d9;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0rem 2rem 1rem;
  box-sizing: border-box;
  border-radius: 0px 16px 0px 0px;
}

/* Add this to your CSS file */
.scrollable {
  overflow-y: auto;
  max-height: 400px; /* Set a max height to enable scrolling */
}


.chatBox121 {
  width: 90%;
  background-color: #ffffff;
  border-radius: 1rem 1rem 1rem 0rem;
  padding: 1rem;
}

.chatBox121-Head {
  /* font-family: Poppins; */
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000;
  margin: 4px 0;
}

.chatBox121-tes {
  /* font-family: Poppins; */
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #616161;
  margin: 0;
}

.chatBox2 {
  width: 100%;
  height: 20%;
  border: 0.27px solid #aaaaaa;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatBox21 {
  background-color: #f4f4f7;
  width: 80%;
  height: 60%;
  border-radius: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.chatBox211 {
  width: calc(44%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  border-radius: 16px;
  background-color: #ffffff;
}

.chatBox212 {
  width: calc(44%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  border-radius: 16px;
  background-color: transparent;
}

.chatBox121-align {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chatBox121-div {
  width: 100%;
  display: block;
  margin: auto;
  border: 0.27px solid #215071;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #616161;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.chatBox121-divA {
  width: 100%;
  display: block;
  margin: auto;
  border: 0.27px solid #215071;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #616161;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #d8eefd;
}

.chatBox121Send {
  width: 90%;
  background-color: #215071;
  border-radius: 1rem 1rem 0rem 1rem;
  padding: 1rem;
  display: block;
  margin: auto;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #ffffff;
}
